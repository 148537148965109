import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import { Link } from "react-router-dom";
import Fragment from "../../utils/Fragment";
import navlogo from "../../components/Assets/senseilogowhite.png";
import NavSearch from "./NavSearch";
import "./Navbar.css";

const Navbar = (props) => {
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();

  const [settingstoggled, setSettingstoggled] = useState(false);
  const [settingstoggledhalf, setSettingstoggledhalf] = useState(false);

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(actions.logoutUser());
  };

  const eventSwitch = () => {
    console.log("Here");
    reduxState.dashboard.switchEvent = false;
    //  window.location.href = '/dashboard';
  };

  useEffect(() => {
    console.log("reduxState");
    console.log(reduxState);
    console.log("navbar user");
    // console.log(reduxState.auth.user);
    // console.log(reduxState.auth.user.toptier_subscription);
    setSettingstoggled(false);

    console.log("reduxState.dashboard");
    console.log(reduxState.dashboard.dashboard);
    // let eventsarray = reduxState.dashboard.dashboard;

    return () => {
      setSettingstoggled(false);
    };
  }, [reduxState]);

  const togglesettings = () => {
    setSettingstoggled(!settingstoggled);
  };

  let firstletter = reduxState.user.user.first_name?.charAt(0);
  let secondletter = reduxState.user.user.last_name?.charAt(0);

  let initials = firstletter + secondletter;
  let color = "#606060";
  const customStyle = {
    display: "flex",
    height: "30px",
    width: "30px",
    borderRadius: "100px",
    color: "white",
    background: color,
    marginRight: 0,
    marginTop: -4,
  };

  const needAuthentication = (
    <Fragment>
      <div style={{ display: "flex" }}>
        <li className="unloggednavbar_full" style={{fontFamily: "Tahoma" }}>
          <Link
            
            
            className="nav-link"
            to="/pricing"
          >
            <i className="fas fa-tag fa-sm"></i> Pricing
          </Link>
        </li>
        <li className="unloggednavbar_full" style={{ fontFamily: "Tahoma" }}>
          <Link
            
            
            className="nav-link"
            to="/contact"
          >
            <i className="fas fa-mail-forward fa-sm"></i> Contact
          </Link>
        </li>
        <li className="unloggednavbar_full" style={{ fontFamily: "Tahoma" }}>
          <Link
            
            
            className="nav-link"
            to="/signup"
          >
            <i className="fas fa-user-edit fa-sm"></i> Sign Up
          </Link>
        </li>
        <li className="unloggednavbar_full lastnavitem" style={{ fontFamily: "Tahoma" }}>
          <Link
            
            
            className="nav-link"
            to="/login"
          >
            <i className="fas fa-sign-in-alt fa-sm"></i> Login
          </Link>
        </li>
      </div>
      {settingstoggled ? (
        <div
          className="unloggednavbar_half"
          style={{
            // borderTop: "1px solid gray",
            position: "absolute",
            marginTop: "47px",
            marginLeft: "-115px",
          }}
        >
          <Link
            
            
            className="nav-link"
            to="/pricing"
            style={{ margin: 0, padding: 0 }}
            onClick={() => setSettingstoggled(false)}
          >
            <li
              className="nav_sublink"
              style={{
                background: "#343A3F",
                fontWeight: "bold",
                padding: 10,
                fontSize: 15,
                paddingLeft: 20,
              }}
            >
             <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-tag fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Pricing</div>
                </div>
            </li>
          </Link>

          <Link
            
            
            className="nav-link"
            to="/contact"
            style={{ margin: 0, padding: 0 }}
            onClick={() => setSettingstoggled(false)}
          >
            <li
              className="nav_sublink"
              style={{
                background: "#343A3F",
                fontWeight: "bold",
                fontSize: "14px",
                padding: 10,
                paddingRight: 80,
                paddingLeft: 20,
              }}
            >
             <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-mail-forward fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Contact</div>
                </div>
            </li>
          </Link>

          <Link
            
            
            className="nav-link"
            to="/signup"
            style={{ margin: 0, padding: 0 }}
            onClick={() => setSettingstoggled(false)}
          >
            <li
              className="nav_sublink"
              style={{
                background: "#343A3F",
                fontWeight: "bold",
                fontSize: "14px",
                padding: 10,
                paddingRight: 60,
                paddingLeft: 20,
              }}
            >
             <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-user-edit fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Sign Up</div>
                </div>
            </li>
          </Link>

          <Link
            
            
            className="nav-link"
            to="/login"
            style={{ margin: 0, padding: 0 }}
            onClick={() => setSettingstoggled(false)}
          >
            <li
              className="nav_sublink"
              style={{
                background: "#343A3F",
                fontWeight: "bold",
                fontSize: "14px",
                padding: 10,
                paddingRight: 60,
                paddingLeft: 20,
                paddingBottom: 15,
                borderBottomLeftRadius: 15,
              }}
            >
             <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-sign-in-alt fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Login</div>
                </div>
            </li>
          </Link>
        </div>
      ) : null}

      <div>
        <li className="unloggednavbar_half">
          <i
            onClick={() => {
              setSettingstoggled(!settingstoggled);
              setSettingstoggledhalf(false);
            }}
            style={{ fontSize: 28, marginTop: 0 }}
            className="fas fa-navicon fa-sm togglernav"
          ></i>
        </li>
      </div>
    </Fragment>
  );

  const guestAuthenticated = (
    <Fragment>
      {/* <NavBtn /> */}
      {reduxState.auth.user.toptier_subscription ? (
        <li
          style={{ fontFamily: "Tahoma" }}
          className="nav-item fullsize"
          id="fullsize"
          
        >
          <Link
            
            
            className="nav-link fullsize"
            id="fullsize"
            to="/office"
          >
            <i className="fas fa-star fa-sm" style={{ marginRight: "2px" }}></i>
            Office
          </Link>
        </li>
      ) : null}
      <li
        style={{ fontFamily: "Tahoma" }}
        onClick={eventSwitch}
        className="nav-item"
      >
        <Link
          
          
          className="nav-link fullsize"
          id="fullsize"
          to="/dashboard"
        >
          <i className="fas fa-users fa-sm"></i> Events
        </Link>
      </li>
      {/* <li style={{fontFamily: "Tahoma"}} className="nav-item">
          <Link
            
            
            className="nav-link"
            to='/dashboard'>
            <i className="far fa-list-alt fa-sm"></i> Guest List</Link>
        </li> */}
      {reduxState.auth.user.role === "Owner Original" ||
      reduxState.auth.user.role === "Owner" ||
      reduxState.auth.user.role === "Admin" ||
      reduxState.auth.user.role === "Editor" ? (
        <li
          style={{ fontFamily: "Tahoma" }}
          className="nav-item fullsize"
          id="fullsize"
          
        >
          <Link
            
            
            className="nav-link fullsize"
            id="fullsize"
            to="/import"
          >
            <i className="fas fa-arrow-up fa-sm"></i> Imports
          </Link>
        </li>
      ) : null}

      {reduxState.auth.user.toptier_subscription ||
      reduxState.auth.user.superuser ? (
        <li
          style={{ fontFamily: "Tahoma" }}
          className="nav-item"
          
        >
          <Link
            className="nav-link fullsize"
            id="fullsize"
            to="/invite"
          >
            <i className="fas fa-users fa-sm"></i> Invite
          </Link>
        </li>
      ) : null}
      <li
        style={{ fontFamily: "Tahoma" }}
        className="nav-item"
        
      >
        <Link
          
          
          className="nav-link fullsize"
          id="fullsize"
          to="/reports"
        >
          <i className="fas fa-download fa-sm"></i> Reports
        </Link>
      </li>

      <li style={{ fontFamily: "Tahoma" }} className="nav-item">
        <Link
          
          
          className="nav-link fullsize"
          id="fullsize"
          to="/analytics"
        >
          <i className="fas fa-user fa-bar-chart"></i> Analytics
        </Link>
      </li>

      <li style={{ fontFamily: "Tahoma" }} className="nav-item">
        <Link
          
          
          className="nav-link fullsize"
          id="fullsize"
          to="/subscribe"
        >
          <i className="fas fa-handshake-o"></i> Subscribe
        </Link>
      </li>

      {/* Dropdown2 */}

      <li
        id="navdropdowncontrol"
        style={{ fontFamily: "Tahoma" }}
        className="fullsize"
      >
        <div
          onClick={togglesettings}
          className="nav-link"
          style={{
            fontWeight: "bold",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={customStyle}>
              <span style={{ margin: "auto" }}> {initials} </span>
            </div>

            <button
              style={{ marginTop: -5, background: "none", border: "none" }}
            >
              Settings
            </button>
          </div>
        </div>
        {settingstoggled ? (
          <div style={{ position: "absolute", marginTop: "7px" }}>
            <Link
              
              
              className="nav-link"
              to="/settings"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  fontSize: 15,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-gear fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Account</div>
                </div>
              </li>
            </Link>

            <Link
              
              
              className="nav-link"
              to="/volunteers"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  paddingRight: 60,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-users fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Volunteers</div>
                </div>
              </li>
            </Link>
            <Link
              
              
              className="nav-link"
              to="/chapters"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  paddingRight: 60,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-flag fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Chapters</div>
                </div>
              </li>
            </Link>
            <Link
              
              
              className="nav-link"
              to="/"
              onClick={(e) => {
                onLogout(e);
                setSettingstoggled(false);
              }}
              style={{ margin: 0, padding: 0 }}
            >
              <li
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "15px",
                  padding: 10,
                  paddingBottom: 12,
                  borderBottomLeftRadius: 15,
                  paddingLeft: 17,
                  fontSize: "14px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-sign-out-alt fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Log out</div>
                </div>
              </li>
            </Link>
          </div>
        ) : null}
      </li>

      {/* Dropdown1 */}
      <div id="halfsize" style={{ fontFamily: "Tahoma" }} className="halfsize">
        <div
          onClick={togglesettings}
          className="nav-link"
          style={{
            fontWeight: "bold",
          }}
        >
          {/* <div style={{ display: "flex" }}>
            <div style={customStyle}>
              <span style={{ margin: "auto" }}> {initials} </span>
            </div>
            <button
              style={{ marginTop: -5, background: "none", border: "none" }}
            >
              Settings
            </button>
          </div> */}
        </div>
        {settingstoggled ? (
          <div
          className="shortMenuBar"
            style={{
              position: "absolute",
              marginTop: "25px",
              marginLeft: "-117px",
            }}
          >
            <Link
              
              
              className="nav-link"
              to="/dashboard"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  fontSize: 15,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-users fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Events</div>
                </div>
              </li>
            </Link>

            <Link
              
              
              className="nav-link"
              to="/import"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  paddingRight: 80,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-arrow-up fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Imports</div>
                </div>
              </li>
            </Link>


            {reduxState.auth.user.toptier_subscription ||
      reduxState.auth.user.superuser ? (
        <li
          style={{ fontFamily: "Tahoma" }}
          className="nav-item"
          
        >
            <Link
              
              
              className="nav-link"
              to="/invite"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  paddingRight: 60,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-download fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Invite</div>
                </div>
              </li>
            </Link>
        </li>
      ) : null}

            <Link
              
              
              className="nav-link"
              to="/reports"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  paddingRight: 60,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-download fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Reports</div>
                </div>
              </li>
            </Link>

            {/* <Link
              
              
              className="nav-link"
              to="/analytics"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  paddingRight: 60,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-bar-chart fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Analytics</div>
                </div>
              </li>
            </Link> */}

            <Link
              
              
              className="nav-link"
              to="/subscribe"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setSettingstoggled(false)}
            >
              <li
                className="nav_sublink"
                style={{
                  background: "#343A3F",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 10,
                  paddingRight: 60,
                  paddingLeft: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 4 }}>
                    <i className="fas fa-handshake-o fa-sm"></i>
                  </div>
                  <div style={{ fontSize: "14px" }}>Subscribe</div>
                </div>
              </li>
            </Link>

            <li
              style={{
                background: "#343A3F",
                fontWeight: "bold",
                fontSize: "15px",
                padding: 6,
                paddingBottom: 12,
                borderBottomLeftRadius: settingstoggledhalf ? 0 : 20,
                paddingLeft: 12,
                fontSize: "14px",
              }}
            >
              <div
                className="nav-link"
                style={{ display: "flex" }}
                onClick={() => setSettingstoggledhalf(!settingstoggledhalf)}
              >
                <div style={customStyle}>
                  <span style={{ margin: "auto" }}> {initials} </span>
                </div>{" "}
                <button style={{ background: "none", border: "none" }}>
                  Settings
                </button>
              </div>

              {settingstoggledhalf ? (
                <div>
                  <li
                    style={{
                      background: "#CBCECF",
                      marginTop: 10,
                      paddingTop: 1,
                    }}
                    className="divider"
                  ></li>
                  <div className="shortNavBarCSSMobile"

                    style={{
                      position: "absolute",
                      marginTop: "7px",
                      marginLeft: "-12px",
                    }}
                  >
                    <Link
                      
                      
                      className="nav-link"
                      to="/settings"
                      style={{ margin: 0, padding: 0 }}
                      onClick={() => setSettingstoggled(false)}
                    >
                      <li
                        style={{
                          background: "#343A3F",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "15px",
                          padding: 2,
                        }}
                        className="divider"
                      ></li>
                      <li
                        className="nav_sublink"
                        style={{
                          background: "#343A3F",
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingBottom: 10,
                          fontSize: 15,
                          paddingLeft: 20,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: 4 }}>
                            <i className="fas fa-gear fa-sm"></i>
                          </div>
                          <div style={{ fontSize: "14px" }}>Account</div>
                        </div>
                      </li>
                    </Link>

                    <Link
                      
                      
                      className="nav-link"
                      to="/volunteers"
                      style={{ margin: 0, padding: 0 }}
                      onClick={() => setSettingstoggled(false)}
                    >
                      <li
                        className="nav_sublink"
                        style={{
                          background: "#343A3F",
                          fontWeight: "bold",
                          fontSize: "14px",
                          padding: 10,
                          paddingRight: 60,
                          paddingLeft: 20,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: 4 }}>
                            <i className="fas fa-users fa-sm"></i>
                          </div>
                          <div style={{ fontSize: "14px" }}>Volunteers</div>
                        </div>
                      </li>
                    </Link>

                    <Link
                      
                      
                      className="nav-link"
                      to="/chapters"
                      style={{ margin: 0, padding: 0 }}
                      onClick={() => setSettingstoggled(false)}
                    >
                      <li
                        className="nav_sublink"
                        style={{
                          background: "#343A3F",
                          fontWeight: "bold",
                          fontSize: "14px",
                          padding: 10,
                          paddingRight: 60,
                          paddingLeft: 20,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: 4 }}>
                            <i className="fas fa-flag fa-sm"></i>
                          </div>
                          <div style={{ fontSize: "14px" }}>Chapters</div>
                        </div>
                      </li>
                    </Link>
                    <Link
                      
                      
                      className="nav-link"
                      to="/"
                      onClick={(e) => {
                        onLogout(e);
                        setSettingstoggled(false);
                      }}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <li
                        style={{
                          background: "#343A3F",
                          fontWeight: "bold",
                          fontSize: "15px",
                          padding: 10,
                          paddingBottom: 12,
                          borderBottomLeftRadius: 15,
                          paddingLeft: 20,
                          fontSize: "14px",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: 4 }}>
                            <i className="fas fa-sign-out-alt fa-sm"></i>
                          </div>
                          <div style={{ fontSize: "14px" }}>Log out</div>
                        </div>
                      </li>
                    </Link>
                  </div>
                </div>
              ) : null}
            </li>
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: -5 }}>
        <li id="halfsize">
          <i
            onClick={() => {
              setSettingstoggled(!settingstoggled);
              setSettingstoggledhalf(false);
            }}
            style={{ fontSize: 28, marginTop: -15 }}
            className="fas fa-navicon fa-sm togglernav"
          ></i>
        </li>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <div className="NavHeader" style={{ paddingBottom: 50 }}>
        {/* <div className=""> */}
        <div className="Header">
          {/* <nav className="navbar fixed-top navbar-expand-lg navbar navbar-dark bg-dark NavBk"> */}
          <nav className="navbar fixed-top navbar-expand-lg navbar navbar-dark bg-dark NavBk">
            <div className="container-fluid">
              <div className="navbar-header">
                <Link className="" to="/">
                  <img
                    color="white"
                    style={{ marginTop: 8, float: "left" }}
                    width="150"
                    src={navlogo}
                    alt="Logo"
                  />
                </Link>
                <div
                className="navSearchDiv"
                  style={{
                    float: "left",
                    marginLeft: 20,
                    marginTop: 6,
                  }}
                >
                  {reduxState.auth.isAuthenticated ? <NavSearch /> : null}
                </div>
              </div>

              <div>
                {/* <button
                  className="navbar-toggler"
                  type="button"
                  
                  
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button> */}

                {/* <div className="collapse navbar-collapse" id="navbarNav"> */}
                <div className="navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">
                    {reduxState.auth.isAuthenticated
                      ? guestAuthenticated
                      : needAuthentication}
                  </ul>
                </div>
                {/* {guestAuthenticated} */}
              </div>
            </div>
          </nav>
          {/* </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
