import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import QRCode from "react-qr-code";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import GuestTicket from "./guestTicket";
import "./EditGuestModal.css";
import { Link, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import * as action from "../../../store/actions/lists";
import { useDispatch, useSelector } from "react-redux";
import { set } from "lodash";

const EditGuestModal = (props) => {
  const [viewTicket, setViewTicket] = useState(false);
  const dispatch = useDispatch();


  const history = useHistory();

  const [formData, setFormData] = useState({
    guestname: props.list,
    email: props.email,
    phone: props.phone,
    tickettype: props.tickettype,
    guest_details: props.guest_details,
    organization: props.organization,
    table_row_number: props.table_row_number,
    seat_number: props.seat_number,
    guestPicture: props.guestPicture,
    guestPictureId: props.guestPictureId,
    guestPictureName: props.guestPictureName,
    guestPictureType: props.guestPictureType,
  });

  const [myImage, setMyImage] = useState("");
  const [currentGuestPicture, setCurrentGuestPicture] = useState({});
  const [guestPictureName, setGuestPictureName] = useState("");
  const [guestPictureType, setGuestPictureType] = useState("");
  const [imageUploadButton, setImageUploadButton] = useState(false);

  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [randomImageId, setRandomImageId] = useState("");

  const [pictureEdited, setPictureEdited] = useState(false);
  const [pictureDeleted, setPictureDeleted] = useState(false);
  const [pictureAdded, setPictureAdded] = useState(false);

  useEffect(() => {
    console.log("currentGuestPicture");
    console.log(currentGuestPicture);

    setFormData({
      guestname: props.list,
      email: props.email,
      phone: props.phone,
      tickettype: props.tickettype,
      guest_details: props.guest_details,
      organization: props.organization,
      table_row_number: props.table_row_number,
      seat_number: props.seat_number,
      guestPicture: props.guestPicture,
      guestPictureId: props.guestPictureId,
    });
  }, [
    currentGuestPicture,
    imageUploadButton,
    props.id,
    props.dashId,
    props.list,
    props.email,
    props.phone,
    props.tickettype,
    props.guest_details,
    props.organization,
    props.table_row_number,
    props.seat_number,
    props.guestPicture,
    props.guestPictureId,
  ]);

  console.log("props over here");
  console.log(props);

  //Single Event Handler Approach:
  let onChange = (e) => {
    console.log("formData");
    console.log(formData);

    console.log("props");
    console.log(props);

    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // this.setState({ [e.target.name]:
    //     e.target.value});

    console.log(formData.name);
    console.log(formData.email);
  };

  const onEditImage = (e) => {
    if (e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpg" ||
      e.target.files[0].type == "image/heif" ||
      e.target.files[0].type == "image/heic" ||
      e.target.files[0].type == "image/JPEG" ||
      e.target.files[0].type == "image/PNG" ||
      e.target.files[0].type == "image/JPG" ||
      e.target.files[0].type == "image/HEIF" ||
      e.target.files[0].type == "image/HEIC"
    ) {

    setPictureEdited(true)
    setPictureAdded(false)
    setPictureDeleted(false)
    setImageUploadButton(false)

    console.log("onEditImage")
    console.log(e.target.files[0])

    const img = e.target.files[0]
    setMyImage(URL.createObjectURL(img))

    const formDataPic = new FormData();
    formDataPic.append("image", e.target.files[0]);

    // let randomImageId = Math.random().toString(36).slice(2)

    formDataPic.append("guestPictureId", props.guestPictureId);
    formDataPic.append("guestId", props.id);
    formDataPic.append("guestPictureName", e.target?.files[0]?.name);
    formDataPic.append("guestPictureType", e.target.files[0].type);
    formDataPic.append("guestPictureNameOld", props.guestPictureName);
    formDataPic.append("guestPictureTypeOld", props.guestPictureType);
    formDataPic.append("dashboardId", props.dashId);

    console.log("editPicture formDataPic")
    console.log(formDataPic)

    var guestPictureName = e.target.files[0].name
    var guestPictureType = e.target.files[0].type

    setCurrentGuestPicture(formDataPic);
    setGuestPictureName(guestPictureName);
    setGuestPictureType(guestPictureType);

    // console.log("formDataPic")
    // console.log(formDataPic)


    console.log("picture Edited!!!!")
    console.log(pictureEdited)
    }
    else {
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>Please upload a valid image file type (jpeg, jpg, png)</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return
    }
  }

  const onSubmit = (e) => {

    console.log("picture Edited at sent!!!!")
    console.log(pictureEdited)
    // e.preventDefault();

    e.preventDefault();

    console.log("formData add Guest")
    console.log(formData)

    console.log("here is current Guest Picture")
    console.log(currentGuestPicture)

    console.log("submit");
    console.log(formData.guestname);
    console.log(formData.email);
    console.log(formData.phone);
    console.log(formData.tickettype);
    console.log(formData.guest_details);
    console.log(formData.organization);
    console.log(formData.table_row_number);
    console.log(formData.seat_number);
    console.log(formData.guestPicture);

    if(pictureDeleted == true) {
      let currentGuestPictureData = {
        guestPictureId: props.guestPictureId,
        guestId: props.id,
        guestPictureName: props.guestPictureName,
        guestPictureType: props.guestPictureType,
        dashboardId: props.dashId
      }

      axios.post('/api/emailtemplates/delete_guest_image2', currentGuestPictureData)
        .then(res => {
          console.log("res")
          console.log(res)
        })
        .catch(err => {
          console.log(err.response)
        }
        )

        if(pictureDeleted == true && pictureAdded == false) {
        props.editGuestList(
          props.dashId,
          props.id,
          formData.guestname,
          formData.email,
          formData.phone,
          formData.tickettype,
          formData.guest_details,
          formData.organization,
          formData.table_row_number,
          formData.seat_number,
          formData.guestPicture
        );
      }

      dispatch(action.deleteListImage(props.dashId, props.id));
    }

    if (pictureEdited == true || pictureAdded == true) {

      console.log("picture Loaded!!!!")

      axios.post('/api/emailtemplates/upload_guest_image', currentGuestPicture)
        .then(res => {
          console.log("res")
          console.log(res)
          console.log("res.data Location")
          console.log(res.data.data.Location)
          setFormData({ ...formData, guestPicture: res.data.data.Location, guestPictureId: randomImageId, guestPictureName: fileName, guestPictureType: fileType });

          console.log("formData after setting guestPicture")
          console.log(formData)

          let guest_picture_new = res.data.data.Location
          dispatch(action.editListImage(props.dashId, props.id, guest_picture_new, guestPictureName, guestPictureType));
        })
        .catch(err => {
          console.log(err.response)
        }
      )

      if(pictureEdited == true && pictureAdded == false) {

      setTimeout(() => {
        axios.post('/api/emailtemplates/edit_guest_image', currentGuestPicture)
          .then(res => {
            console.log("res")
            console.log(res)
            console.log("res.data Location")
            console.log(res.data.data.Location)
            console.log('props.guestPictureId')
            console.log(props.guestPictureId)

            let guest_picture_new = res.data.data.Location
            dispatch(action.editListImage(props.dashId, props.id, guest_picture_new, guestPictureName, guestPictureType));
            // setFormData({ ...formData, guestPicture: res.data.data.Location, guestPictureId: randomImageId });
          })
          .catch(err => {
            console.log(err.response)
          }
          )
      }, 1000);
    }

    setTimeout(() => {
      props.editGuestList(
      props.dashId,
      props.id,
      formData.guestname,
      formData.email,
      formData.phone,
      formData.tickettype,
      formData.guest_details,
      formData.organization,
      formData.table_row_number,
      formData.seat_number,
      formData.guestPicture
    );
    }, 2000);
  }
    else {

      console.log("no picture Loaded!!!!")

      props.editGuestList(
        props.dashId,
        props.id,
        formData.guestname,
        formData.email,
        formData.phone,
        formData.tickettype,
        formData.guest_details,
        formData.organization,
        formData.table_row_number,
        formData.seat_number,
        formData.guestPicture
      );
    }
    props.onHide();

    toast.success(
      <div style={{ fontSize: "16px" }}>
        <b>Guest Edited!</b>
      </div>,
      {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

  }

  const closeEditModal = () => {
    props.onHide();
    setViewTicket(false);
    setImageUploadButton(false);
    setPictureEdited(false);
    setPictureDeleted(false);
    setPictureAdded(false);
    setMyImage("");

    setFormData({
      guestname: props.list,
      email: props.email,
      phone: props.phone,
      tickettype: props.tickettype,
      guest_details: props.guest_details,
      organization: props.organization,
      table_row_number: props.table_row_number,
      seat_number: props.seat_number,
      guestPicture: props.guestPicture,
      guestPictureId: props.guestPictureId,
    });

  };

  const guestprofile = () => {
    //Redirect to another route
    history.push(`/guests/${props.dashId}/${props.id}`);
  };

  const deleteGuestPicture = () => {
    setMyImage("")
    setImageUploadButton(true)
    setFormData({ ...formData, guestPicture: "" });
    setPictureDeleted(true)
    setPictureEdited(false)
    setPictureAdded(false)
  }


  const printDocument = () => {
    const input = document.getElementById("divToPrint");

    try {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imgData, "JPEG", 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("ticket_guest_eventsensei.pdf");
      });

      toast.success(
        <div style={{ fontSize: "16px" }}>
          <b>Guest ticket downloaded!.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch {
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>An error occured downloading guest ticket.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const onGuestImage = (e) => {
    console.log("onGuestImage")
    console.log(e.target.files[0])

    if (e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpg" ||
      e.target.files[0].type == "image/heif" ||
      e.target.files[0].type == "image/heic" ||
      e.target.files[0].type == "image/JPEG" ||
      e.target.files[0].type == "image/PNG" ||
      e.target.files[0].type == "image/JPG" ||
      e.target.files[0].type == "image/HEIF" ||
      e.target.files[0].type == "image/HEIC"
    ) {

    setPictureAdded(true)
    setPictureEdited(false)
    // setPictureDeleted(false)
    setImageUploadButton(false)

    const img = e.target.files[0]
    setMyImage(URL.createObjectURL(img))

    let randomImageId = Math.random().toString(36).slice(2)

    const formDataPic = new FormData();
    formDataPic.append("image", e.target.files[0]);
    formDataPic.append("guestPictureId", randomImageId);
    formDataPic.append("guestPictureName", e.target?.files[0]?.name);
    formDataPic.append("guestPictureType", e.target?.files[0]?.type);
    formDataPic.append("dashboardId", props.id);

    var fileName = e.target.files[0].name
    var fileType = e.target.files[0].type

    setFileName(fileName)
    setFileType(fileType)
    setRandomImageId(randomImageId)

    setGuestPictureName(fileName)
    setGuestPictureType(fileType)

    setCurrentGuestPicture(formDataPic)

    console.log("formDataPic")
    console.log(formDataPic)
    }
    else {
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>Please upload a valid image file type (jpeg, jpg, png)</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return
    }
  }

  return (
    <div>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => closeEditModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontSize: "21px" }}
            className="editmodaltitle"
            id="contained-modal-title-vcenter"
          >
          <div style={{ display: 'flex' }}>
            <div style={{ width: 150, fontFamily: "Verdana, Geneva, Helvetica" }}>
              <b>Edit Guest</b>
            </div>

        {/* {(props.guestPicture == "") && ( */}
        {imageUploadButton ? (
            <div>
              <div style={{ height: 0, overFlow: "hidden" }}>
                <input type="file" id="guestPicture" name="guestPicture"
                  onChange={(e) => onGuestImage(e)} />
              </div>
              <Button variant='info' type="button" onClick={() => document.getElementById('guestPicture').click()}>
                Upload Guest Picture
                </Button>
            </div>
          ) : null}
                    {/* )} */}
              </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!viewTicket ? (
            <div>
              <form onSubmit={onSubmit}>
                <div>
                  {!imageUploadButton ?
                    <div>
                      {props.guestPicture !== "" ? (
                        <div className="form-group-custom guestPictureLogo"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            className="profileimage"
                            style={{
                              overflow: "hidden",
                              border: "1px solid gray",
                              borderRadius: "50%",
                              width: "80px",
                              height: "80px",
                            }}
                            src={myImage == "" ? props.guestPicture : myImage}
                            // src={"https://www.earth.com/assets/_next/image/?url=https%3A%2F%2Fcff2.earth.com%2Fuploads%2F2023%2F06%2F02100547%2FMountain-2-1400x850.jpg&w=1200&q=75"}
                            alt="profie pic"
                          ></img>

                          <div>
                            <div style={{ height: 0, overFlow: "hidden" }}>
                              <input type="file" id="guestPictureEdit" name="guestPicture"
                                onChange={(e) => onEditImage(e)}
                              />
                            </div>

                            <i
                              className="fas fa-remove fa-xl deletePicture"
                              style={{ color: "crimson", position: "absolute", marginTop: "2px", marginLeft: "1px", cursor: 'pointer', fontSize: "20px" }}
                              onClick={() => deleteGuestPicture()}
                            ></i>

                            <i
                              className="fas fa-pen fa-xl editPicture"
                              style={{ color: "#6699FF", position: "absolute", marginTop: "35px", marginLeft: "4px", cursor: 'pointer', fontSize: "15px" }}
                              onClick={() => document.getElementById('guestPictureEdit').click()}
                            ></i>
                            {/* <Button variant='info' type="button" onClick={() => document.getElementById('guestPicture').click()}>Upload Guest Picture</Button> */}
                          </div>
                        </div>
                      ) : (
                        null)}
                    </div>
                    : null}

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="name">
                      <b>Name</b>
                    </label>
                    <input
                      id="name"
                      type="text"
                      name="guestname"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Name..."
                      defaultValue={props.list}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="organization">
                      <b>Organization</b>
                    </label>
                    <input
                      id="organization"
                      type="text"
                      name="organization"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Organization..."
                      defaultValue={props.organization}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="email">
                      <b>Email </b>
                    </label>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Email..."
                      defaultValue={props.email}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="phone">
                      <b>Phone</b>
                    </label>
                    <input
                      id="phone"
                      type="text"
                      className="form-control form-control-md"
                      name="phone"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Phone..."
                      defaultValue={props.phone}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "48%" }} className="form-group-custom">
                      <label className="editmodaltext" htmlFor="ticket">
                        <b>Ticket</b>
                      </label>
                      <input
                        id="ticket"
                        type="text"
                        name="tickettype"
                        className="form-control form-control-md"
                        style={{ padding: "5px", border: ".5px solid black" }}
                        placeholder="Ticket Type..."
                        defaultValue={props.tickettype}
                        onChange={(e) => onChange(e)}
                      />
                    </div>

                    <div style={{ width: "48%" }} className="form-group-custom">
                      <label className="editmodaltext" htmlFor="ticket">
                        <b>Table/Row #</b>
                      </label>
                      <input
                        id="table_row_number"
                        type="text"
                        name="table_row_number"
                        className="form-control form-control-md"
                        style={{ padding: "5px", border: ".5px solid black" }}
                        placeholder="Table #..."
                        defaultValue={props.table_row_number}
                        onChange={(e) => onChange(e)}
                      />
                    </div>

                  </div>

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="ticket">
                      <b>Seat #</b>
                    </label>
                    <input
                      id="seat_number"
                      type="text"
                      name="seat_number"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Seat #..."
                      defaultValue={props.seat_number}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="ticket">
                      <b>Additional Details</b>
                    </label>
                    <textarea
                      id="guest_details"
                      type="text"
                      name="guest_details"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Additional Details..."
                      defaultValue={props.guest_details}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </form>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <button
                  className="viewTicket"
                  onClick={() => setViewTicket(true)}
                  style={{ float: "left" }}
                >
                  <b>View Ticket</b>
                </button>
                <button
                  className="viewTicket"
                  onClick={() => guestprofile()}
                  style={{ float: "left" }}
                >
                  <b>View Profile</b>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    View Guest Ticket
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div id="divToPrint">
                    <GuestTicket guest={props} />
                  </div>
                  <div className="mb5">
                    <button
                      style={{ padding: 7 }}
                      className="viewTicketButton"
                      onClick={printDocument}
                    >
                      <b>Download Ticket</b>
                    </button>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="info" onClick={() => setViewTicket(false)}>
                    Go back to Editing
                  </Button>
                  <Button variant="danger" onClick={() => closeEditModal()}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onSubmit}>
            Submit
          </Button>
          <Button variant="danger" onClick={() => closeEditModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default EditGuestModal;
