import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { isEmpty } from "../../utils/Validation";
// import DashboardList from "./DashboardLists/DashboardLists";
import Fragment from "../../utils/Fragment";
import "./Dashboard.css";
import * as action from "../../store/actions/index";
import Button from "react-bootstrap/Button";
// import { Alert, Grid } from "@mui/material";
import moment from "moment";
import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Dashboard = (props) => {
  const [eventName, setEventName] = useState("");
  const [activeItemId, setActiveItemId] = useState(0);
  const [editedEvent, setEditedEvent] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [grantAccess, setGrantAccess] = useState(false);

  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  useEffect(() => {
    reduxState.dashboard.switchEvent = false;
    console.log("Dashboard Did mount");
    setLoading(true);
    axios
      .get("/api/dashboard/")
      .then((res) => {
        console.log("axios response");
        console.log(res.data.dashboard);
        setAllEvents(res.data.dashboard);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
      });

    dispatch(action.fetchDashboard());
    // console.log(props.state);
    // console.log(reduxState.dashboard.dashboard);
    // console.log(JSON.stringify(reduxState.dashboard.dashboard));
    // console.log(props);

    if (reduxState.dashboard.eventName === "") {
      // reduxState.dashboard.switchEvent = false
      // switchEvent = false
    } else {
      // reduxState.dashboard.switchEvent = true
      // switchEvent = true
    }
    // console.log("SWITCH TEST");
    // console.log(reduxState.dashboard);

    // const MINUTE_MS = 40000;
    // const interval = setInterval(() => {
    //   this.props.fetchDashboard();
    //   this.props.clearDashmessage();
    // }, MINUTE_MS);

    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {

    // Grant Access
    console.log("grant access");
    console.log(grantAccess);
    console.log(reduxState);
    console.log(reduxState.dashboard);

    try {
      // ['new york']
      let userChaptersAssigned = reduxState.auth.user.chapter_volunteer.map(
        (arr) => arr.chapters_assigned
      );
      // nyyp
      let ChapterAssignedFirst = reduxState.dashboard.dashboard[0].chapter;
      // ['nyyp', 'nyyp']
      let ChaptersAssigned = reduxState.dashboard.dashboard.map(
        (arr) => arr.chapter
      );

      if (ChaptersAssigned.toString().includes(userChaptersAssigned)) {
        //  ['nyyp', 'nyyp'] includes ['new york']
        console.log("first suceeds");
        setGrantAccess(true);
      } else {
        console.log("first fails");
      }
      if (ChapterAssignedFirst.includes(userChaptersAssigned)) {
        // nyyp includes ['new york']
        console.log("second suceeds");
        setGrantAccess(true);
      } else {
        console.log("first fails");
      }
    } catch {
      console.log("chapter assigned error");
    }

    // return () => {
    //   second
    // }
  }, []);

  const showEvent = (itemid) => {
    reduxState.dashboard.eventid = itemid;
    setEventName(itemid);
    reduxState.dashboard.eventName = itemid;
    reduxState.dashboard.switchEvent = true;
    dispatch(action.fetchDashboard());
    // switchEvent = true'
    // this.setState({well: true})
  };


  let authRedirect = null;
  if (!reduxState.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }

  let dashboard = reduxState.dashboard;
  let dashboardItems = null;
  if (isEmpty(dashboard.dashboard)) {
    dashboardItems = (
      <Fragment>
        <div className="col-sm-6 offset-sm-3">
          <div className="card Empty">
            <h5 className="card-header">
              {dashboard.mesg ? dashboard.mesg.message : ""}
            </h5>
            <div></div>
          </div>
        </div>
        <div className="col-sm-3"></div>
      </Fragment>
    );
  } else {
    dashboardItems = dashboard.dashboard
      .filter((book) => book._id === reduxState.dashboard.eventName)
      .map((dashItem, i) => {
        return (
          <div>...</div>
          // <DashboardList
          //   addGuestReact={this.addGuestReact}
          //   key={dashItem._id}
          //   dashItem={dashItem}
          // />
        );
      });
  }

  let events = (
    <div className="noevents" style={{ textAlign: "center", padding: 30 }}>
      <h2 style={{ marginLeft: 20 }} className="noevents">
        No Events Created or Accesible...
      </h2>
    </div>
  );

  if (!isEmpty(reduxState.dashboard.dashboard)) {
    events = <h2 className="display-4 None">Not Empty.</h2>;
   // Chapter Specific Access
   let userChaptersAssigned = reduxState.auth.user.chapter_volunteer.map(
    (arr) => arr.chapters_assigned
  );

  let allowedevents = [];

  if(reduxState.auth.user.role === "Owner Original") {
    allowedevents = reduxState.dashboard.dashboard;
  }
  else if(reduxState.auth.user.chapter_volunteer.filter((item)=>item.chapters_assigned === "All Events").length > 0){
    allowedevents = reduxState.dashboard.dashboard;
  }
  else{
  let filteredevents = reduxState.dashboard.dashboard.filter(
    (item) => item.chapter_event
  );

  let dashboardchapters = reduxState.dashboard.dashboard.filter(
    (item) => item.chapter_dashboard.map((items)=>items.chapter_dash_names).some(r=> userChaptersAssigned.includes(r)))
  // arr1.some(r=> arr2.includes(r))
  console.log("dashboardchapters")
  console.log(dashboardchapters)

  allowedevents = dashboardchapters

  allowedevents.push(
    reduxState.dashboard.dashboard.filter(
      (item) => item.chapter_dashboard.map((items)=>items.chapter_dash_names).some(r => r === "All Users"))[0]
  );

  allowedevents.push(
    filteredevents.filter((item) => item.chapter_event === "All Users")[0]
  );

  // Remove any undefined
  let undefinedfilter = allowedevents.filter((item)=>item !== undefined)
    allowedevents = undefinedfilter
  }
    events = allowedevents.map((item) => (
      <div
        className="d-flex flex-row mb-3"
        style={{
          margin: 10,
          paddingLeft: 30,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Link to={`/analytics/${item._id}`}>
          <Button
            id='eventlistbutton'
            variant="light"
            className="eventlist"
            style={{
              paddingRight: 10,
              paddingLeft: 15,
              paddingBottom: 10,
              opacity: 0.8,
              boxShadow: "3px 3px 9px dimgray",
              border: ".9px solid gray",
              borderRadius: 10,
              minWidth: 280,
              fontFamily: "Verdana",
            }}
            onClick={() => showEvent(item._id)}
          >
            <div style={{ marginRight: 50 }} className="eventname_label">
              <b>Event Name:</b> {item.name}
            </div>
            <div className="additionaldetails_label">
              <b>Location:</b> {item.location.length < 35 ? item.location : item.location.slice(0, 35) + "..."}
            </div>
            {item.startdate == null ? (
              <div className="additionaldetails_label">
                <b>Start Date:</b>
              </div>
            ) : (
              <div className="additionaldetails_label">
                <b>Start Date:</b>{" "}
                {moment(item.startdate).utc().format("MM/D/YY")}
              </div>
            )}
            {item.enddate == null ? (
              <div className="additionaldetails_label">
                <b>End Date:</b>
              </div>
            ) : (
              <div className="additionaldetails_label">
                <b>End Date:</b> {moment(item.enddate).utc().format("MM/D/YY")}
              </div>
            )}

            {item.starttime === "" ? (
              <div className="additionaldetails_label">
                <b>Event Time:</b>{" "}
              </div>
            ) : (
              <div>
                {item.starttime === null ? (
                  <div className="additionaldetails_label">
                    <b>Event Time:</b>{" "}
                  </div>
                ) : (
                  <div className="additionaldetails_label">
                    <b>Event Time:</b>{" "}
                    {moment(item.starttime, "HH:mm").format("hh:mm A")}
                  </div>
                )}
              </div>
            )}

            {item.timezone === "" ? (
              <div className="additionaldetails_label">
                <b>Timezone:</b> {item.timezone}
              </div>
            ) : (
              <div className="additionaldetails_label">
                <b>Timezone:</b> {item.timezone.length < 35 ? item.timezone : item.timezone.slice(0, 35) + "..."}
              </div>
            )}
            <div className="additionaldetails_label">
            <b>Chapter:</b> {(item.chapter_dashboard.map((items)=>items.chapter_dash_names)).join(", ")}
            </div>
          </Button>
        </Link>
      </div>
    ));
  }

  return (
    <div>
      <div>
        {/* <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        /> */}

        {/* {reduxState.dashboard.dashMessage !== "" ? (
          <Grid container justifyContent="flex-end">
            <Alert
              className="float-right"
              style={{
                position: "fixed",
                zIndex: 1,
                marginTop: "1%",
                marginRight: "1%",
                border: ".1px solid gray",
              }}
              severity="success"
            >
              <div
                className="float-right"
                style={{
                  alignSelf: "flex-end",
                  textAlign: "center",
                }}
              >
                <b>{reduxState.dashboard.dashMessage}</b>
              </div>
            </Alert>
          </Grid>
        ) : null} */}

        {/* {reduxState.dashboard.dashMessageSuccess !== "" ? (
          <Grid container justifyContent="flex-end">
            <Alert
              className="float-right"
              style={{
                position: "fixed",
                zIndex: 1,
                marginTop: "1%",
                marginRight: "1%",
                border: ".1px solid gray",
              }}
              severity="success"
            >
              <div
                className="float-right"
                style={{
                  alignSelf: "flex-end",
                  textAlign: "center",
                }}
              >
                <b>{reduxState.dashboard.dashMessageSuccess}</b>
              </div>
            </Alert>
          </Grid>
        ) : null} */}

        {authRedirect}
        {/* {authRedirecet} */}
        {!reduxState.dashboard.switchEvent ? (
          <div>
            {/* {reduxState.auth.user.role === "user" || "admin" ? ( */}
              <div>
                <h1
                  className="eventlist_titles"
                  style={{
                    textAlign: "center",
                    marginTop: 35,
                    marginBottom: 15,
                  }}
                >
                  <b style={{fontFamily: "Verdana, Geneva, Helvetica"}}>Analytics Dashboard</b>
                </h1>
              </div>
            {/* ) : null} */}
            <hr></hr>
            <div
                  style={{ paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}
                >
                  <h3>
                    Choose an event to view it's Analytics.
                  </h3>
                </div>
            {/* <div style={{ textAlign: "center" }}>
                <h1 className="eventlist_titles">
                  <b>Choose an Event</b>
                </h1>
                <br />
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {events}
              </div>
          </div>
        ) : (
          <div className="listcontainer cardelementcontainer">
            {dashboardItems}
          </div>
        )}
      </div>
    </div>
  );
};

// const mapStateToProps = (state, ownProps) => {
//   return {
//     dashboards: state.dashboard,
//     isAuthenticated: state.auth.isAuthenticated,
//     user: state.auth.user,
//   };
// };

export default Dashboard;
