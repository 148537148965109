import React, { useState, useEffect } from "react";
import Fragment from "../../../utils/Fragment";
import DashHeading from "../../../components/Dashboards/Dashboard/DashHeading";
import ListItems from "../Lists/Lists";
// import styled from "@emotion/styled";
// import DeleteEventModal from "./DeleteEventModal";
import AddGuestModal from "./AddGuestModal.js";
import "./DashbboardList.css";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { isEmpty } from "../../../utils/Validation";
import * as actions from "../../../store/actions/index";

const DashboardLists = (props) => {

  const [listItem, setListItem] = useState("")
  const [showAddModal, setShowAddModal] = useState(false)
  const [guests, setGuests] = useState([])
  const [lodaded, setLoaded] = useState()
  // const [reduxloaded, setReduxLoaded] = useState()

  const accountDetails = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {

    console.log("accountDetails")
    console.log(accountDetails)
    
    setInterval(() => {
      console.log("fetching dashboard")
      dispatch(actions.fetchDashboard());
    }, "30000");
    // if(accountDetails.dashboard.dashboard && props.match.params.eventId != null){
    //   setReduxLoaded(accountDetails.dashboard.dashboard.filter((event) => event._id == props.match.params.eventId)[0])
    //   }

    //   console.log(accountDetails.dashboard.dashboard.filter((event) => event._id == props.match.params.eventId)[0])
    // console.log("reduxloaded")
    // console.log(reduxloaded)

    // let filteredevents = events.filter((event) => event._id == props.match.params.eventId)
    console.log("events")
    // console.log(accountDetails.dashboard.dashboard.filter((event) => event._id == props.match.params.eventId)[0])
    // console.log(filteredevents)

    // if(accountDetails.dashboard.dashboard && props.match.params.eventId != null) {
    // setReduxLoaded(accountDetails.dashboard.dashboard.filter((event) => event._id == props.match.params.eventId)[0])
    //    }

//   // let filteredevents = events.filter((event) => event._id == props.match.params.eventId)
//   // console.log(Object.values(events).filter(user => user.user_id === props.match.params.eventId));

// console.log("filtered events")
// console.log(filteredevents)


console.log("props.match.params")
// console.log(props.match.params.eventId)



axios.get('/api/dashboard/')
.then(res => {
console.log(res.data.dashboard)
console.log(res.data.dashboard.filter((event) => event._id == "6593edba48dea30014fd9cdc")[0])
setLoaded(res.data.dashboard.filter((event) => event._id == props.match.params.eventId)[0])
})
.catch(err => {
console.log(err.response)
})

  }, [])

const GuestAddition = (
    _id,
    list,
    email,
    phone,
    tickettype,
    guest_details,
    organization,
    table_row_number,
    seat_number,
    guestPicture,
    guestPictureId,
    guestPictureName,
    guestPictureType
  ) => {
    //Note diffrence _id and id if moving back to Redux
    // props.addDashboardList(
    //   id,
    //   list,
    //   email,
    //   phone,
    //   tickettype,
    //   organization,
    //   table_row_number,
    //   seat_number
    // );

    console.log("runnner");
    dispatch(actions.addList(
      _id,
      list,
      email,
      phone,
      tickettype,
      guest_details,
      organization,
      table_row_number,
      seat_number,
      guestPicture,
      guestPictureId,
      guestPictureName,
      guestPictureType
    ))

    // axios.post('/api/lists/create', {_id, list, email, phone, tickettype, organization, table_row_number, seat_number})
    // .then(res => {
    //   console.log("res")
    //   console.log(res)
    //   props.fetchDashboard()
    // })
    // .catch(err => {
    //   console.log(err.response)
    //   console.log(err.response.data.mesg)
    // })

    setListItem("")
  };

    let closeAddModal = () => setShowAddModal(false);


//     axios.get('/api/dashboard/')
//     .then(res => {
// console.log("final try")
// console.log(res.data.dashboard)
// console.log(res.data.dashboard.filter((event) => event._id == "6593edba48dea30014fd9cdc")[0])
// setLoaded(res.data.dashboard.filter((event) => event._id == "6593edba48dea30014fd9cdc")[0])
// })
// .catch(err => {
//   console.log(err.response)
// })

// let dashboardList = res.data.dashboard.filter((event) => event._id == "6593edba48dea30014fd9cdc")[0]
let dashboardList = null

// if (!isEmpty(this.props.dashboards)) {

let violet = accountDetails.dashboard.dashboard

if(!isEmpty(violet)){
dashboardList = accountDetails.dashboard.dashboard.filter((event) => event._id == props.match.params.eventId)[0]
}
else {
  // Crazy approach here. Got frustrated over a undefined error bug, so just created a blank object.
 dashboardList = 
      {
        "_id": "...",
        "user": "...",
        "name": "...",
        "location": "...",
        "startdate": "...",
        "enddate": "...",
        "starttime": "...",
        "endtime": "...",
        "timezone": "...",
        "additionaldetails": "...",
        "associatedemail": "...",
        "lists": [
            {
                "status": "...",
                "_id": "...",
                "list": "...",
                "email": "...",
                "phone": "...",
                "tickettype": "...",
                "guest_details": "...",
                "organization": "...",
                "table_row_number": "...",
                "seat_number": "...",
                "guestPicture": "...",
                "checkedin_by": "...",
                "checkedin_by_username": "...",
                "date": "..."
            }
        ],
        "date": "...",
        "createdAt": "...",
        "updatedAt": "...",
        "__v": 1
    }
      
  }
      let listItems = (
        <ListItems
          addGuestReact={props.addGuestReact}
          dashId={dashboardList?._id}
          lists={dashboardList?.lists}
          dashboardList={props.dashItem}
          guests={guests}
        />
      );

      let dashboard = (
        <div className="cardelementcontainer">
          <div className="card List" style={{ padding: 5 }}>
            <div className="card-body" style={{ background: "#E4E5E6" }}>
              <div
                style={{
                  justifyContent: "space-between",
                  alignContent: "space-between",
                  display: "flex",
                  flex: 0,
                  flexDirection: "row",
                }}
              >
                <div>
                  <DashHeading
                    dashHeadingId={dashboardList?._id}
                    dashHeadingTime={dashboardList?.date}
                    dashHeadingName={dashboardList?.name}
                  />
                </div>
  
                <AddGuestModal
                  show={showAddModal}
                  onHide={closeAddModal}
                  id={dashboardList?._id}
                  addGuest={GuestAddition}
                />
  
                <div
                  style={{
                    color: "#505050",
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontFamily: "Verdana",
                    marginRight: 15,
                  }}
                >
                  <span className="guestnumber">
                    {/* {dashboardList?.lists.filter((item) => item.status === "Checked In").length}
                    / */}
                    {dashboardList?.lists.filter( x => x.status == "Checked In").length}
                    /
                    {dashboardList?.lists.length}
                    {dashboardList?.lists.length <= 1 ? " Checked In" : " Checked In"}
                  </span>
                  {accountDetails.auth.user.role === "Owner Original" || accountDetails.auth.user.role === "Owner" || accountDetails.auth.user.role === "Admin" ? 
                  <Button
                    className="addguestbutton"
                    style={{
                      height: 40,
                      borderRadius: 0,
                      marginBottom: 2,
                    }}
                    variant="info"
                    onClick={() => setShowAddModal(true)}
                  >
                    ADD GUESTS
                  </Button>
                  : null}
                </div>
              </div>
              {listItems}
            </div>
          </div>
        </div>
      );

        return <Fragment>{dashboard}</Fragment>;

}


export default DashboardLists;