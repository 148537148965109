import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../store/actions/index";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from '../../../components/Spinner/Spinner';
import { ToastContainer, toast } from "react-toastify";

const emailTemplates = [
    { id: 1, name: 'Welcome Email', subject: 'Welcome to our service' },
    { id: 2, name: 'Password Reset', subject: 'Reset your password' },
    { id: 3, name: 'Newsletter', subject: 'Monthly Newsletter' },
];

const EmailTemplates = (props) => {

    const dispatch = useDispatch();

    const [allowedevents, setAllowedEvents] = useState([]);
    const [templatesLoaded, setTemplatesLoaded] = useState(false);
    const [loadingDeleted, setLoadingDeleted] = useState(false);
  
    const reduxState = useSelector((state) => state);
  
      useEffect(() => {
      axios.get('/api/emailtemplates')
      .then(res => {
        console.log("res email templates")
        console.log(res)
        setTemplatesLoaded(true);
        setAllowedEvents(res.data.emailtemplates)
      }).catch(err => {
        console.log(err.response)
      })
    },[])
  
    const fieldPreviewTranslator = ( field ) => {
      let formattedValue =  field.replace(/<\/p>/g, '</div>').replace(/<p>/g, '<div>')
      return formattedValue
    }
  
    const handleLoadTemplate = (id) => {
      console.log("id handleLoadTemplate");
      console.log(id);
      dispatch(action.set_redux_template(id));
  
      console.log("Redux stater")
      console.log(reduxState)
      setTimeout(() => {
        props.history.push(`/invite/${props.match.params.eventId}/${id}`);
      }, 1000)
    }
    
    const handleCustomTemplate = (item) => {
      console.log("Custom Template")
      console.log("item")
      console.log(item)

      dispatch(action.custom_redux_template());

      setTimeout(() => {
      props.history.push(`/invite/${props.match.params.eventId}/${item._id}`);
      }, 1000)
    }


    let authRedirect = null;
    if (!reduxState.auth.isAuthenticated) {
      authRedirect = <Redirect to="/" />;
    }

    const handleTemplateDelete = (item) => {
        console.log("item")
        console.log(item)
        axios.delete(`/api/emailtemplates/${item._id}`)
        .then(res => {
            setLoadingDeleted(true);
            console.log(res)
            axios.get('/api/emailtemplates')
            .then(res => {
              console.log("res email templates")
              console.log(res)
              setAllowedEvents(res.data.emailtemplates)
              setLoadingDeleted(false);
              
              toast.warning(
                <div style={{ fontSize: "16px" }}>
                  <b>Template Deleted!</b>
                </div>,
                {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
            }).catch(err => {
              console.log(err.response)
            })
        }).catch(err => {
            console.log(err.response)
            toast.error(
                <div style={{ fontSize: "16px" }}>
                  <b>An error occurred deleting the template.</b>
                </div>,
                {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
        })
    }

    const handleCloneTemplate = (item) => {
        console.log("item")
        console.log(item)
        axios.post(`/api/emailtemplates/clone/${item._id}`)
        .then(res => {
            console.log(res)
            axios.get('/api/emailtemplates')
            .then(res => {
              console.log("res email templates")
              console.log(res)
              setAllowedEvents(res.data.emailtemplates)

              toast.success(
                <div style={{ fontSize: "16px" }}>
                  <b>Template Cloned!</b>
                </div>,
                {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
            }).catch(err => {
              console.log(err.response)
            })
        }).catch(err => {
            console.log(err.response)
            toast.error(
                <div style={{ fontSize: "16px" }}>
                  <b>An error occurred cloning the template.</b>
                </div>,
                {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
        })
    }
  
    return (
        <div>
        {authRedirect}
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {!templatesLoaded ? 
        <div style={{position:'absolute', marginTop:'15%', left: '46%'}}><Spinner /></div> :
        <div>
        <div>
            {loadingDeleted ? <div style={{position:'absolute', marginTop:'15%', left: '46%'}}><Spinner /></div> : null}
              <h1
                className="eventlist_titles"
                style={{
                  textAlign: "center",
                  marginTop: 35,
                  marginBottom: 15,
                }}
              >
                <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
                  Email Templates
                </b>
              </h1>

              <div className="noevents" style={{position:'absolute', right: 30, top: 20, padding: 30 }}>
                <button style={{marginTop: '40px'}} className="ui primary button" onClick={(e)=>props.history.push('/invite')}>Create New Template</button>
            </div>
            </div>
            {allowedevents.length === 0 ?
            <div className="noevents" style={{ textAlign: "center", padding: 30 }}>
                <h2 style={{ marginLeft: 20 }} className="noevents">
                    No Templates Created or Accesible...
                </h2>
            </div>
            :
        <table style={{marginTop: '50px', marginLeft: '60px', marginRight:'50px'}}>
        <thead>
                <tr>
                    <th style ={{color: 'gray', fontSize: '16px', fontFamily: 'Verdana', paddingLeft: '60px'}}>Name</th>
                    <th style ={{color: 'gray', fontSize: '16px', fontFamily: 'Verdana', paddingLeft: '60px'}}>Description</th>
                    <th style ={{color: 'gray', fontSize: '16px', fontFamily: 'Verdana', paddingLeft: '60px'}}>Subject (Previous)</th>
                    <th style ={{color: 'gray', fontSize: '16px', fontFamily: 'Verdana', paddingLeft: '40px'}}>Type</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {allowedevents.map((item, index) => (
                    <tr style={{borderTop: '1px solid silver', borderBottom: '1px solid silver'}} key={item._id}>
                        <td style={{ paddingLeft: '60px', paddingTop: '40px', paddingBottom:'40px', minWidth:'380px'}}>
                          <Button
                          onClick={()=>handleCustomTemplate(item)} 
                          style={{textDecoration:'none'}}><span style={{ fontWeight: 'bold', fontSize:'18px', color: 'orange'}}>{item.email_template_name}
                        </span></Button>
                        <br/>
                        Created by: {item.email_template_created_by}<br/>
                        Updated: {moment(item.email_template_updated_date).format('MM/DD/YY, h:mm A')}
                        </td>
                        <td style={{ paddingLeft: '60px', paddingTop: '40px', paddingBottom:'40px', minWidth:'290px'}}><span sty>{item.email_template_description}</span></td>
                        <td style={{paddingLeft: '60px', paddingTop: '40px', paddingBottom:'40px', minWidth:'290px'}}><span>{item.email_template_subject}</span></td>
                             <td style={{paddingLeft: '40px', paddingTop: '40px', paddingBottom:'40px', minWidth:'220px'}}><span style={{fontSize: '16px'}}>{item.email_template_category}</span></td>
                        <td style={{paddingRight: '60px', paddingTop: '40px', paddingBottom:'40px'}}>
            <DropdownButton
            style={{fontSize:'16px', border:'1px solid silver', borderRadius: '2px'}}
            // key={variant}
            id={`dropdown-variants`}
            variant={'light'}
            title={'Actions'}
            size="lg"
          >
            <Dropdown.Item eventKey="1"><Link to={`/invite/${props.match.params.eventId}/${item._id}`}><span style={{fontSize:'17px', color: '#505050'}}><i class="pencil icon"></i> Edit</span></Link></Dropdown.Item>
            <Dropdown.Item onClick={(e)=>handleCloneTemplate(item)} eventKey="2"><span style={{fontSize:'17px', color: '#505050'}}><i class="copy icon"></i> Duplicate</span></Dropdown.Item>
            <Dropdown.Item eventKey="3"><Link to='/settings'><span style={{fontSize:'17px', color: '#505050'}}><i class="envelope icon"></i> Mail Settings</span></Link></Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={(e)=>handleTemplateDelete(item)} eventKey="4"><span style={{fontWeight:'bold', color:"crimson", fontSize:'17px'}}><i class="delete icon"></i> Delete</span></Dropdown.Item>
          </DropdownButton>
          </td>
                    </tr>
                ))}
            </tbody>
        </table>
        }
        </div>
    }
        </div>
    );
};

export default EmailTemplates;