import React, { useState, useEffect, useRef } from "react";
import {Redirect} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../store/actions/index";
import Fragment from "../../../utils/Fragment";
import DashHeading from "../../../components/Dashboards/Dashboard/DashHeading";
import styled from "@emotion/styled";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./EmailTicketsWizard.css";
import axios from "axios";
// import qrvector from "./qrvectorimage.png";
import { isEmpty } from "../../../utils/Validation";
import ReactQuill, { Quill } from "react-quill";
import Select from "react-select";
import "quill/dist/quill.snow.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "semantic-ui-react";
import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from "quill-image-uploader";
import BlotFormatter from "quill-blot-formatter";
import AWS from 'aws-sdk';

Quill.register("modules/imageUploader", ImageUploader);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);

const CardElementcontainer = styled.div`
  width: 99%;
  margin-top: 2%;
  margin-left: 0.5%;

  @media screen and (max-width: 900px) {
    width: 99%;
    margin-top: 2%;
    margin-left: 0.5%;
  }

  @media screen and (max-width: 400px) {
    width: 99%;
    margin-top: 2%;
    margin-left: 0.5%;
  }
`;

const EmailTicketsWizard = (props) => {
  const [listItem, setlistItem] = useState("");
  const [items, setItems] = useState([]);
  const [disableSendInvitation, setDisableSendInvitation] = useState(false);
  const [messageSubject, setMessageSubject] = useState("");
  const [messageSender, setMessageSender] = useState("");
  const [inviteMessage, setInviteMessage] = useState("");
  const [inviteSignature, setInviteSignature] = useState("");
  const [inviteSalutation, setInviteSalutation] = useState("");
  const [inviteMessageServer, setInviteMessageServer] = useState("");
  const [inviteSignatureServer, setInviteSignatureServer] = useState("");
  // const [salutationPreview, setSalutationPreview] = useState("");
  const [uniqueTextEditor] = useState(Math.floor(Math.random() * 10000));
  const [editorHtml, setEditorHtml] = useState("");
  const [validationError, setValidationError] = useState("");
  const [submitPressed, setSubmitPressed] = useState(props.submitPressed);
  const [submitPressedChange, setSubmitPressedChange] = useState(true);
  const [lastLength, setLastLength] = useState(true);
  const [minimalError, setminimalError] = useState(false);
  const [qrCode, setQrCode] = useState(true);
  const [currentIndexEmail, setCurrentIndexEmail] = useState(0);
  const [defaultEmailMessage, setDefaultEmailMessage] = useState('');
  const [previewCount, setPreviewCount] = useState(0);
  const [redirectInvite, setRedirectInvite] = useState(false);
  const [saveTemplateModalShow, setSaveTemplateModalShow] = useState(false);
  const [testEmailModal, setTestEmailModal] = useState(false);

  // State to show inputs required for test email
  const [testEmailSubject, setTestEmailSubject] = useState(false);
  const [testEmailSender, setTestEmailSender] = useState(false);
  const [testEmailRecipient, setTestEmailRecipient] = useState(false);

    // State to show inputs required for save and continue
    const [saveEmailName, setSaveEmailName] = useState(false);
    const [saveEmailDescription, setSaveEmailDescription] = useState(false);
    const [saveEmailSubject, setSaveEmailSubject] = useState(false);
    const [saveEmailSender, setSaveEmailSender] = useState(false);
    const [saveEmailCategory, setSaveEmailCategory] = useState(false);
    
  const [bestSenderName, setBestSenderName] = useState("");

  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [formData, setFormData] = useState({
    email_template_name: "",
    email_template_description: "",
    email_template_subject: "",
    email_template_sender: "",
    email_template_category: "",
    });

  const [testEmailData, setTestEmailData] = useState({
    email_subject: "",
    email_recipient: "",
    email_sender: ""
    });

  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const quillRef = useRef();

  //  const onClick = (id) => {
  //     dispatch(action.deleteDashboard(id));
  //     dispatch(action.switchEventFalsy(id));
  //     // reduxState.dashboard.switchEvent = false
  //   };

  useEffect(() => {

    let bestSender = reduxState.user.user.first_name + " " + reduxState.user.user.last_name;
    if(reduxState.user.user.organization !== ""){bestSender = reduxState.user.user.organization}
    setBestSenderName(bestSender)

    try{
      console.log("use effect wizard working")
      let emailTemplate = reduxState.user.user.current_email_template;

      console.log("emailTemplate")
      console.log(emailTemplate)

      // console.log("emailTemplate")
      // console.log(emailTemplate)

      if(emailTemplate === "default_template"){
      let defaultMessage = `<p>Hello {Name},</p><p><br></p><p>Thank you for purchasing a ticket to attend our Event.</p><p><br></p><p><strong>Event Name: </strong>{Event Name}</p><p><strong>Event Date:</strong> {Event Date}</p><p><br></p><p><strong>Name:</strong> {Name}</p><p><strong>Organization:</strong> {Organization}</p><p><strong>Table/Row:</strong> {Table or Row Number}</p><p><strong>Seat:</strong> {Seat Number}</p><p><strong>Ticket:</strong> {Ticket Type}</p><p><strong>Confirmation Number:</strong> {Confirmation Number}</p>`
      setDefaultEmailMessage(defaultMessage)
      setInviteMessage(defaultMessage);
      dispatch(action.clear_email_template());

      } else if (emailTemplate === "") {
        setDefaultEmailMessage("")
      }

        axios.get('/api/emailtemplates')
        .then(res => {
          try{
           console.log('sanity check')
           console.log('res')
           console.log(res)
          let templateHtml = res.data.emailtemplates.filter(item => item._id === emailTemplate)[0].email_template_body
          setDefaultEmailMessage(templateHtml)
          setInviteMessage(templateHtml)
          } catch (error) {
            console.log("error")
            console.log(error)
          }

          if(emailTemplate === "custom_template"){

            console.log("it is a custom_template dawg")
            
            console.log("res------------------")
            console.log(res)
            console.log(res.data)
            console.log(res.data.emailtemplates)

            let current_template_array = res.data.emailtemplates
            console.log("current_template")
            console.log(current_template_array)

            let current_template = current_template_array.filter(item => item._id === props.match.params.templateId)[0]

            console.log("current_template")
            console.log(current_template)

            setDefaultEmailMessage(current_template.email_template_body)
            setInviteMessage(current_template.email_template_body)
            // setFormData({
            //   ...formData,
            //   email_template_name: current_template.email_template_name,
            //   email_template_description: current_template.email_template_description,
            //   email_template_subject: current_template.email_template_subject,
            //   email_template_sender: current_template.email_template_sender,
            //   email_template_category: current_template.email_template_category,
            // });
            
            } 

          console.log("res")
          console.log(res)

          // console.log("templateHtml")
          // console.log(templateHtml)

        }).catch(err => {
          console.log(err.response)
        })

    }catch(error){
      console.log('error')
      console.log(error)
    } 

    dispatch(action.clear_email_template());
    
    console.log("inviteMessager")
    console.log(inviteMessage)

  }, []);

  const useDefaultEmailMessage = () => {
    dispatch(action.default_redux_template());
    // axios.put("/api/users/default_email_template").then((res) => {
    //   console.log(res)
    // });
    // let defaultMessage = `<p>Hello {Name},</p><p><br></p><p>Thank you for purchasing a ticket to attend our Event.</p><p><br></p><p><strong>Event Name: </strong>{Event Name}</p><p><strong>Event Date:</strong> {Event Date}</p><p><br></p><p><strong>Name:</strong> {Name}</p><p><strong>Organization:</strong> {Organization}</p><p><strong>Table/Row:</strong> {Table or Row Number}</p><p><strong>Seat:</strong> {Seat Number}</p><p><strong>Ticket:</strong> {Ticket Type}</p><p><strong>Confirmation Number:</strong> {Confirmation Number}</p>`
    // setDefaultEmailMessage(defaultMessage)
    let emailTemplate = reduxState.user.user.current_email_template;
    console.log("emailTemplate2")
    console.log(emailTemplate)

    setTimeout(() => {
    window.location.reload()
    }, 1000);
  }

  const handleUpload = event => {

  console.log("event")
    console.log(event) 
    
    // event.preventDefault();
    // const data = new FormData(event.target);
    // data.append("file", selectedFile, description);

    // console.log("data")
    // console.log(data)

    // axios
    //   .post(endpoint, data)
    //   .then(() => {
    //     this.props.history.push("/");
    //   })
    //   .catch(error => {
    //     alert("Oops some error happened, please try again");
    //   });
  };

  const handleEmailBodyChange = (html) => {

    console.log("component did mount");
    console.log("redux state");
    console.log(reduxState.dashboard);

    
    console.log("emailBodyChangeRun")
    // console.log(quillRef.current.getEditor().getSelection().index)
    try{
    let position = quillRef?.current?.getEditor()?.getSelection()?.index
    console.log("position")
    console.log(position)
    setCurrentIndexEmail(position)
    console.log("currentIndexEmail")
    console.log(currentIndexEmail)
    } catch (error) {
      console.log("error")
    }

    
    setEditorHtml(html);
    // props.onChange(html, props.name);

    const strippedHtml = html.replace(/<[^>]*>/g, "");
    setLastLength(strippedHtml);
    setSubmitPressedChange(false);

    // If the User has typed over ten characters, but deletes characters below ten, throw warning
    if (
      lastLength.length == 10 &&
      strippedHtml.length <= 9
    ) {
      setValidationError(`Provide a minimum of 10 characters`);
      setminimalError(true);
    }
    // If the User has typed over ten characters, buut deletes a characters to zero, throw warning
    else if (strippedHtml.length == 0) {
      setValidationError(`Email Message Body is required`);
    }
    // If the User has typed a * or |; throw warning
    else if (strippedHtml.includes("*") || strippedHtml.includes("|")) {
      setValidationError();
    }
    // Remove not empty warning if characters are not zero
    else if (strippedHtml != null) {
      setValidationError("");
    }
    // Remove warning label if over 9 characters reached
    if (strippedHtml.length > 9) {
      setminimalError(false);
    }

    console.log("Email Body Change");
    console.log(html);

    setInviteMessage(html);
  };

  const handleOnBlur = () => {
    // Perform Text Validations
    const strippedHtml = editorHtml.replace(/<[^>]*>/g, "");
    if (strippedHtml.length === 0) {
      setValidationError(`Email Message Body is required`);
    } else if (strippedHtml.includes("|") || strippedHtml.includes("*")) {
      setValidationError(`This text cannot a "|" or "*" characters`);
    } else if (strippedHtml.length > 0 && strippedHtml < 11) {
      setValidationError("Provide a minimum of 10 characters");
    }
  };

  // ADA Rich HTML Text Editor - Set Aria-Label
  const uniqueTextEditorAria = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] div[class='ql-editor ql-blank']`
  );
  uniqueTextEditorAria[0]?.setAttribute(
    "aria-label",
    `email message html body`
  );

  // ADA Rich HTML Text Editor Button Aria-Labels
  const boldButtonAria = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] button[class='ql-bold']`
  );
  const italicButtonAria = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] button[class='ql-italic']`
  );
  const undelineButtonAria = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] button[class='ql-underline']`
  );
  const linkButtonAria = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] button[class='ql-link']`
  );
  const clearButtonAria = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] button[class='ql-clean']`
  );
  const colorButtonAria = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] soan[aria-controls='ql-picker-options-1']`
  );
  const backgroundButtonAria = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] soan[aria-controls='ql-picker-options-2']`
  );
  // const headerButton1Aria = document.querySelectorAll(`div[class='uniqueTextEditor${uniqueTextEditor}] button[class='ql-header'][value='1']`);
  // const headerButton2Aria = document.querySelectorAll(`div[class='uniqueTextEditor${uniqueTextEditor}] button[class='ql-header'][value='2']`);

  boldButtonAria[0]?.setAttribute("aria-label", "bold");
  italicButtonAria[0]?.setAttribute("aria-label", "italic");
  undelineButtonAria[0]?.setAttribute("aria-label", "underline");
  linkButtonAria[0]?.setAttribute("aria-label", "link");
  clearButtonAria[0]?.setAttribute("aria-label", "clear");
  colorButtonAria[0]?.setAttribute("aria-label", "text color");
  backgroundButtonAria[0]?.setAttribute("aria-label", "text background color");
  // headerButton1Aria[0]?.setAttribute('aria-label', 'header1');
  // headerButton2Aria[0]?.setAttribute('aria-label', 'header2');

  // Border for Format Text Box
  const formatBoxBorder = document.querySelectorAll(
    `div[class='uniqueTextEditor${uniqueTextEditor}'] span[class='ql-header ql-picker']`
  );
  formatBoxBorder[0]?.setAttribute(
    "style",
    "border: 1px solid #C8C8C8; border-radius: 5px;"
  );

  // const handleClicker = () => {
  //   // console.log(quillRef.current.getEditor().getSelection().index)
  //   let position = quillRef.current.getEditor().getSelection()?.index
  //   let insertedIndex = (position == null || position == undefined ? 0 : position)

  //   quillRef.current.getEditor().insertText(insertedIndex, "Hello, World! ");
  // };


  const emailtemplatecategories = [
    { value: "General", label: "General" },
  ];

  const sendInvites = () => {
    let eventData = {
      eventId: reduxState.dashboard.eventName,
    };

    eventData["messageSubject"] = messageSubject;
    eventData["inviteMessage"] = inviteMessage;
    eventData["inviteSalutation"] = inviteSalutation;
    eventData["inviteSignature"] = inviteSignature;
    eventData["inviteMessageServer"] = inviteMessageServer;
    eventData["inviteSignatureServer"] = inviteSignatureServer;

    // axios
    // .post("/api/users/qremail", eventData)
    // .then((res) => {})
    // .catch((err) => {
    //   console.log(err.response);
    // });
    // this.setState({disableSendInvitation: true})

    axios
      .post("/api/users/nodemailer/", eventData)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log("nodemailer frontend error");
        console.log(err);
      });
  };

  const handleSubjectChange = (event) => {
    const { name, value } = event.target;
    setMessageSubject(value);

    setTestEmailData({
      ...testEmailData,
      email_subject: value,
    });
    
    setFormData({
    ...formData,
    email_template_subject: value,
    });
  };

  const handleSenderChange = (event) => {
    const { name, value } = event.target;
    if(value !== "") {setBestSenderName(value)}
    else if(value === ""){
      let bestSender = reduxState.user.user.first_name + " " + reduxState.user.user.last_name;
      if(reduxState.user.user.organization !== ""){bestSender = reduxState.user.user.organization}
      setBestSenderName(bestSender)
    }

    setMessageSender(value);

    setTestEmailData({
      ...testEmailData,
      email_sender: value,
    });

    setFormData({
      ...formData,
      email_template_sender: value,
      });
  };

  // const handleSalutation = (event) => {
  //   const { name, value } = event.target;
  //   setInviteSalutation(value);
  //   let guestPreview =
  //     " " + reduxState.dashboard.dashboard[0].lists[0].list + ",";
  //   setSalutationPreview(guestPreview);
  // };

  // handleMessageChange = (event) => {
  //   // this.setState({messageSubject: id})
  //   const { name, value } = event.target;
  //   this.setState({
  //     [name]: value
  //   }, () => {
  //     this.setState({ [name]: value });;
  //   });
  //   // consoles inviteMessage
  //   console.log(name)
  //   console.log(value)
  //   let addingBreaksMessage = value
  //   addingBreaksMessage = addingBreaksMessage.replace(/(?:\r\n|\r|\n)/g, '</br>');
  //   this.setState({inviteMessageServer: addingBreaksMessage})
  // };

  // handleSignatureChange = (event) => {
  //   // this.setState({messageSubject: id})
  //   const { name, value } = event.target;
  //   this.setState({
  //     [name]: value
  //   }, () => {
  //     this.setState({ [name]: value });;
  //   });
  // // consoles inviteMessage
  //   console.log(name)
  //   console.log(value)

  //   let addingBreaksSignature = value
  //   addingBreaksSignature = addingBreaksSignature.replace(/(?:\r\n|\r|\n)/g, '</br>');
  //   this.setState({inviteSignatureServer: addingBreaksSignature})
  // };

  // let salutations = ["Dear", "Hi", "Hello", "Greetings"];

  if (!isEmpty(reduxState.dashboard)) {
    var specificDashboard = reduxState.dashboard.dashboard.filter(
      (item) => item._id === props.match.params.eventId);
  }

  console.log("specificDashboard");
  console.log(specificDashboard);

   let dashboardList = reduxState.dashboard.dashboard.filter(
    (item) => item._id === props.match.params.eventId)[0];
  // let dashboardList = props.dashItem;

  console.log("dashboardList here");
  console.log(dashboardList);

    const eventfieldoptions = [
    { value: "list", label: "Name" },
    { value: "email", label: "Email" },
    { value: "date", label: "Purchase Date" },
    { value: "phone", label: "Phone" },
    { value: "tickettype", label: "Ticket Type" },
    { value: "organization", label: "Organization" },
    { value: "status", label: "Status" },
    { value: "guest_details", label: "Guest Details" },
    { value: "account_id", label: "Account ID" },
    { value: "seat_number", label: "Seat Number" },
    { value: "table_row_number", label: "Table or Row Number" },
    { value: "confirmation number", label: "Confirmation Number" },
    // { value: "checkedin_by", label: "Checked In By" },
    // { value: "checkedin_by_username", label: "Checked In By Username" },
    // { value: "relationships", label: "Relationships" },
    { value: "event_name", label: "Event Name" },
    { value: "event_date", label: "Event Date" },
    { value: "event_end_date", label: "Event End Date" },
    { value: "event_start_time", label: "Event Start Time" },
    { value: "event_end_time", label: "Event End Time" },
    { value: "event_location", label: "Event Location" },
    { value: "event_timezone", label: "Event Timezone" },
    { value: "event_details", label: "Event Details" },
    { value: "event_id", label: "Event ID" },
  ];

  const onSelectEventField = (event) => {
    // console.log(quillRef.current.getEditor().getSelection().index)
    // let position = quillRef.current.getEditor().getSelection()?.index
    // let insertedIndex = (position == null || position == undefined ? 0 : position)
    try{
    quillRef.current.getEditor().insertText(currentIndexEmail, `{${event.label}}`);
    } catch (error) {
      console.log("error")
      console.log(error)

      toast.warning(
        <div style={{ fontSize: "16px" }}>
          <b>Please Enter a Space between Variables</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }

  const fieldPreviewTranslator = ( field ) => {

    console.log("field")
    console.log(field)

    // let removeEnters = field.replace(/<\/p>/g, '</div>').replace(/<p>/g, '<div>')
    // let addEnters = field.replace(/<\/div>/g, '</p>').replace(/<div>/g, '<p>')

    let html = 
    field.replace(/<\/p>/g, '</div>').replace(/<p>/g, '<div>')
    .replace(/{Name}/g, specificDashboard[0]?.lists[previewCount].list)
    .replace(/{Organization}/g, specificDashboard[0]?.lists[previewCount].organization)
    .replace(/{Phone}/g, specificDashboard[0]?.lists[previewCount].phone)
    .replace(/{Email}/g, specificDashboard[0]?.lists[previewCount].email)
    .replace(/{Status}/g, specificDashboard[0]?.lists[previewCount].status)
    .replace(/{Table or Row Number}/g, specificDashboard[0]?.lists[previewCount].table_row_number)
    .replace(/{Seat Number}/g, specificDashboard[0]?.lists[previewCount].seat_number)
    .replace(/{Ticket Type}/g, specificDashboard[0]?.lists[previewCount].tickettype)
    .replace(/{Guest Details}/g, specificDashboard[0]?.lists[previewCount].guest_details)
    .replace(/{Purchase Date}/g, moment(specificDashboard[0]?.lists[previewCount].date).format("MM-DD-YYYY"))
    .replace(/{Confirmation Number}/g, specificDashboard[0]?.lists[previewCount]._id)
    .replace(/{Event Name}/g, specificDashboard[0].name)
    .replace(/{Event Date}/g, moment(specificDashboard[0].startdate).format("MM-DD-YYYY"))
    .replace(/{Event Date}/g, moment(specificDashboard[0].enddate).format("MM-DD-YYYY"))
    .replace(/{Event Start Time}/g, moment(specificDashboard[0].starttime, 'HH:mm').format('h:mm a'))
    .replace(/{Event End Time}/g, moment(specificDashboard[0].endtime, 'HH:mm').format('h:mm a'))
    .replace(/{Event Location}/g, specificDashboard[0].location)
    .replace(/{Event Timezone}/g, specificDashboard[0].timezone)
    .replace(/{Event ID}/g, specificDashboard[0]._id)
    .replace(/{Event Details}/g, specificDashboard[0].additionaldetails)

    return html

    // .replace(/{Checked In By}/g, specificDashboard[0].lists[0].checkedin_by)
    // .replace(/{Checked In By Username}/g, specificDashboard[0].lists[0].checkedin_by_username)
    // .replace(/{Relationships}/g, specificDashboard[0].lists[0].relationships)

    // {reduxState.dashboard.dashboard[0].name}
  }

  let inviteHomeRedirect = null;
  if (redirectInvite === true) {
    inviteHomeRedirect = <Redirect to="/invite" />;
  }

  let saveTemplateModalOpen = () => setSaveTemplateModalShow(true);
  let saveTemplateModalClose = () => {
    setSaveTemplateModalShow(false);
    setSaveEmailName(false);
    setSaveEmailDescription(false);
    setSaveEmailSubject(false);
    setSaveEmailSender(false);
    setSaveEmailCategory(false);
  }

  let testEmailModalOpen = () => setTestEmailModal(true);
  let testEmailModalClose = () => setTestEmailModal(false);


  const onChangeSaveTemplate = (e) => {
    const { name, value } = e.target;
  
      setFormData({
      ...formData,
      [name]: value,
      });

      console.log("---Save template on change----")
      console.log(formData)
  }

  const onChangeTestEmail = (e) => {
    const { name, value } = e.target;
  
      setTestEmailData({
      ...testEmailData,
      [name]: value,
      });
      console.log("formData111")
      console.log(formData)
  }


  const onSubmitSaveTemplate = (e) => {

    console.log('onSubmitSaveTemplate Run')

    console.log("inviteMessage")
    console.log(inviteMessage)

    let email_template_name = formData.email_template_name;
    let email_template_description = formData.email_template_description;
    let email_template_subject = formData.email_template_subject;
    let email_template_body = inviteMessage;
    let email_template_category = formData.email_template_category;

    console.log("email_template_name")
    console.log(email_template_name)
    console.log("email_template_description")
    console.log(email_template_description)
    console.log("email_template_subject")
    console.log(email_template_subject)
    console.log("email_template_body")
    console.log(email_template_body)


      if(email_template_name === ""){
        setSaveEmailName(true);
      }
  
      if(email_template_description === ""){
        setSaveEmailDescription(true);
      }
  
      if(email_template_subject === ""){
        setSaveEmailSubject(true);
      }

      if(email_template_body === ""){
        setSaveEmailSender(true);
      }
  
      if(email_template_category === ""){
        setSaveEmailCategory(true);
      }
  
      if(email_template_name === "" || email_template_description === "" || email_template_subject === "" || email_template_body === "" || email_template_category === ""){
        return
      }
    


    axios.post('/api/emailtemplates/save_template', { email_template_name, email_template_description, email_template_subject, email_template_body, email_template_category })
      .then(res => {
        console.log("res")
        console.log(res)
        dispatch(action.fetchDashboard());
        saveTemplateModalClose();
        toast.success(
          <div style={{ fontSize: "16px" }}>
            <b>Email Template Saved</b>
          </div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })
      .catch(err => {
        console.log(err.response)
        console.log(err.response.data.mesg)
        toast.error(
          <div style={{ fontSize: "16px" }}>
            <b>An error occurred saving the Email Template</b>
          </div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })

      saveTemplateModalClose();
  }

  let onChangeTemplateCategory = (e) => {
    console.log("e.value");
    console.log(e.value);
    let emailTemplateCategory = e.value;

    setFormData({
      ...formData,
      email_template_category: emailTemplateCategory,
    });
  };

  let authRedirect = null;
  if (!reduxState.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }

  const onSubmitTestEmail = (e) => {

    if(testEmailData.email_subject === ""){
      setTestEmailSubject(true);
    }

    if(testEmailData.email_sender === ""){
      setTestEmailSender(true);
    }

    if(testEmailData.email_recipient === ""){
      setTestEmailRecipient(true);
    }

    if(testEmailData.email_subject === "" || testEmailData.email_sender === "" || testEmailData.email_recipient === ""){
      return
    }

    let email_subject = testEmailData.email_subject;
    let email_sender = testEmailData.email_sender;
    let email_recipient = testEmailData.email_recipient;
    let email_body = fieldPreviewTranslator(inviteMessage);

    axios.post('/api/users/test_email_template', { email_subject, email_sender, email_recipient, email_body })
      .then(res => {
        console.log("res")
        console.log(res)
        testEmailModalClose();
        toast.success(
          <div style={{ fontSize: "16px" }}>
            <b>Test Email Sent</b>
          </div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })
      .catch(err => {
        console.log(err.response)
        console.log(err.response.data.mesg)
        toast.error(
          <div style={{ fontSize: "16px" }}>
            <b>An error occurred sending the Test Email</b>
          </div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })

      testEmailModalClose();
  }

  let dashboard = (
    <CardElementcontainer>
      {inviteHomeRedirect}

      <div>
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ fontFamily: "Georgia" }}
          show={saveTemplateModalShow}
          onHide={saveTemplateModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span style={{fontFamily:"Verdana, Geneva, Helvetica", fontWeight: "bold"}}>Save Template</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form 
            // onSubmit={onSubmit}
            >
            {/* {props.editedEvent.map((item) => ( */}
              <div style={{fontFamily:"Verdana, Geneva, Helvetica"}}>
                <div className="form-group">
                  <label htmlFor="email_template_name">
                    <b>Template Name</b> <b style={{ color: 'darkred' }}>*</b>
                  </label>
                  <input
                    id="email_template_name"
                    type="text"
                    name="email_template_name"
                    className="form-control form-control-md"
                    onBlur={()=> {if(formData.email_template_name.length === 0){setSaveEmailName(true)}else{setSaveEmailName(false)}}}
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    placeholder="Template Name..."
                    // defaultValue={item.name}
                    onChange={onChangeSaveTemplate}
                  />
                  {saveEmailName && formData.email_template_name.length === 0 &&(
                <div class="ui pointing red basic label">
                    Template Name is Required
                </div>
                )}
                </div>
                <div className="form-group">
                  <label htmlFor="email_template_description">
                    <b>Template Desciption</b> <b style={{ color: 'darkred' }}>*</b>
                  </label>
                  <input
                    id="email_template_description"
                    type="text"
                    name="email_template_description"
                    className="form-control form-control-md"
                    onBlur={()=> {if(formData.email_template_description.length === 0){setSaveEmailDescription(true)}else{setSaveEmailDescription(false)}}}
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    placeholder="Description..."
                    // defaultValue={item.location}
                    onChange={onChangeSaveTemplate}
                  />
                {saveEmailDescription && formData.email_template_description.length === 0 &&(
                <div class="ui pointing red basic label">
                   Template Description is Required
                </div>
                )}
                </div>

                <div className="form-group">
                  <label htmlFor="email_template_subject">
                    <b>Email Subject</b> <b style={{ color: 'darkred' }}>*</b>
                  </label>
                  <input
                    id="email_template_subject"
                    type="text"
                    name="email_template_subject"
                    className="form-control form-control-md"
                    onBlur={()=> {if(formData.email_template_subject.length === 0){setSaveEmailSubject(true)}else{setSaveEmailSubject(false)}}}
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    placeholder="Email Subject..."
                    defaultValue={messageSubject}
                    onChange={onChangeSaveTemplate}
                  />
                {saveEmailSubject && formData.email_template_subject.length === 0 &&(
                <div class="ui pointing red basic label">
                    Email Subject is Required
                </div>
                )}
                </div>

                <div className="form-group">
                  <label htmlFor="email_template_sender">
                    <b>Sender Name</b> <b style={{ color: 'darkred' }}>*</b>
                  </label>
                  <input
                    id="email_template_sender"
                    type="text"
                    name="email_template_sender"
                    className="form-control form-control-md"
                    onBlur={()=> {if(formData.email_template_sender.length === 0){setSaveEmailSender(true)}else{setSaveEmailSender(false)}}}
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    placeholder="Sender Name..."
                    defaultValue={bestSenderName}
                    onChange={onChangeSaveTemplate}
                  />
                {saveEmailSender && formData.email_template_sender.length === 0 &&(
                <div class="ui pointing red basic label">
                    Sender Name is Required
                </div>
                
                )}
                </div>

                <div className="form-group">
                <label htmlFor="email_template_category">
                    <b>Email Template Category</b> <b style={{ color: 'darkred' }}>*</b>
                  </label>
                <Select
                  // defaultValue={permissionsData}
                  onChange={onChangeTemplateCategory}
                  options={emailtemplatecategories}
                  onBlur={()=> {if(formData.email_template_category.length === 0){setSaveEmailCategory(true)}else{setSaveEmailCategory(false)}}}
                      />
                {saveEmailCategory && formData.email_template_category.length === 0 &&(
                <div class="ui pointing red basic label">
                    Email Template is Required
                </div>
                )}
                </div>
              </div>
                {/* ))} */}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
            className={"save_and_continue"}
              style={{
                borderRadius: 20,
                marginRight: 15,
                border: "1px solid DimGray",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily:"Verdana, Geneva, Helvetica"
              }}
              variant="success"
              onClick={onSubmitSaveTemplate}
            >
              Save Template and Continue
            </Button>
            <Button
              style={{
                borderRadius: 20,
                width: "17%",
                border: "1px solid DimGray",
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily:"Verdana, Geneva, Helvetica"
              }}
              variant="danger"
              onClick={saveTemplateModalClose}
              className="edit_confirm"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

          {/* Test Email Modal */}

        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ fontFamily: "Georgia" }}
          show={testEmailModal}
          onHide={testEmailModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span style={{fontFamily:"Verdana, Geneva, Helvetica", fontWeight: "bold"}}>Send a Test Email</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form 
            // onSubmit={onSubmit}
            >
            {/* {props.editedEvent.map((item) => ( */}
              <div style={{fontFamily:"Verdana, Geneva, Helvetica"}}>
                <div className="form-group">
                  <label htmlFor="email_subject">
                    <b>Email Subject</b> <b style={{ color: 'darkred' }}>*</b>
                  </label>
                  <input
                    id="email_subject"
                    type="text"
                    name="email_subject"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    onBlur={()=> {if(testEmailData.email_subject.length === 0){setTestEmailSubject(true)}else{setTestEmailSubject(false)}}
                  }
                    placeholder="Email Subject..."
                    defaultValue={testEmailData.email_subject}
                    onChange={onChangeTestEmail}
                  />
                  {testEmailSubject && testEmailData.email_subject.length === 0 &&(
                     <div class="ui pointing red basic label">
                    Email Subject is Required
                    </div>
                  )}
                </div>
             
                <div className="form-group">
                  <label htmlFor="email_sender">
                    <b>Sender Name</b> <b style={{ color: 'darkred' }}>*</b>
                  </label>
                  <input
                    id="email_sender"
                    type="text"
                    name="email_sender"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    onBlur={()=> {if(testEmailData.email_sender.length === 0){setTestEmailSender(true)}else{setTestEmailSender(false)}}}
                    placeholder="Sender Name..."
                    defaultValue={bestSenderName}
                    onChange={onChangeTestEmail}
                  />

                  {testEmailSender && testEmailData.email_sender.length === 0 &&(
                      <div class="ui pointing red basic label">
                    Sender Name is Required
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email_recipient">
                    <b>Email Recipient</b> <b style={{ color: 'darkred' }}>*</b>
                  </label>
                  <input
                    id="email_recipient"
                    type="text"
                    name="email_recipient"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    onBlur={()=> {if(testEmailData.email_recipient.length === 0){setTestEmailRecipient(true)}else{setTestEmailRecipient(false)}}}
                    placeholder="Email Recipient..."
                    // defaultValue={item.location}
                    onChange={onChangeTestEmail}
                  />
                  {testEmailRecipient && testEmailData.email_recipient.length === 0 &&(
                      <div class="ui pointing red basic label">
                    Email Recipient is Required
                    </div>
                  )}
                </div>
              </div>
                {/* ))} */}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
            className={"save_and_continue"}
              style={{
                borderRadius: 20,
                marginRight: 15,
                border: "1px solid DimGray",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily:"Verdana, Geneva, Helvetica"
              }}
              variant="success"
              onClick={onSubmitTestEmail}
            >
              Send Test Email
            </Button>
            <Button
              style={{
                borderRadius: 20,
                width: "17%",
                border: "1px solid DimGray",
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily:"Verdana, Geneva, Helvetica"
              }}
              variant="danger"
              onClick={testEmailModalClose}
              className="edit_confirm"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
      <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <h1
          className="eventlist_titles"
          style={{
            textAlign: "center",
            marginTop: 35,
            marginBottom: 15,
          }}
        >
          <b style={{fontFamily:"Verdana, Geneva, Helvetica"}}>Send Event Invitations</b>
        </h1>
          
      </div>
      <hr className="solid"></hr>
      <div
        className="card List"
        style={{
          marginLeft: 30,
          marginRight: 30,
          padding: 5,
          background: "#F8F8F8",
          border: "1px solid darkgray",
        }}
      >
        <div className="card-body">
          <div className="DashHeader">
            <DashHeading
              dashHeadingId={dashboardList?._id}
              dashHeadingTime={dashboardList?.date}
              dashHeadingName={dashboardList?.name}
            />
          </div>
        </div>
      </div>
      <div style={{borderBottom: '1px solid #808080', display: 'flex', justifyContent:'space-around', paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}>
        <h3 className="Lato">
          <b>Step 2:</b> Send out Invites with QR codes to the Guests in your
          chosen Event.
        </h3>

        <div>
        <Button 
          onClick={useDefaultEmailMessage}
          style={{width: '200px', padding: '10px'}} variant="info"><b>Use Default Template</b>
          </Button>
  </div>

<div>
  <Link to={`/emailtemplates/${props.match.params.eventId}`}>
        <Button 
          // onClick={downloadActivity}
          style={{width: '200px', padding: '10px'}} variant="info"><b>View Saved Templates</b></Button>
  </Link>
  </div>
      </div>
<br/>
      <div className="row">
        <div className="col form-group messageContainer">
          <label for="messageSubject">
            <b>Email Subject:</b>
          </label>
          <input
            id="messageSubject"
            className="form-control form-control-md"
            type="text"
            name="messageSubject"
            style={{ width: '440px', flex: "8", padding: "5px", border: ".5px solid black" }}
            placeholder="Message Subject."
            onChange={handleSubjectChange}
          />

          <br />

          <label for="senderName">
            <b>Sender Name:</b>
          </label>
          <input
            id="senderName"
            className="form-control form-control-md"
            type="text"
            name="senderName"
            style={{ width: '440px', flex: "8", padding: "5px", border: ".5px solid black" }}
            placeholder="Sender Name."
            defaultValue={bestSenderName}
            onChange={handleSenderChange}
          />

          <br />

          {/* <label for="inviteSalutation">
            <b>Message Salutation:</b>
          </label>
          <select
            id="inviteSalutation"
            name="inviteSalutation"
            className="form-control"
            placeholder="Salutation"
            style={{ color: "#707070", border: "1px solid #909090" }}
            defaultValue={""}
            value={inviteSalutation}
            onChange={handleSalutation}
            onClick={handleSalutation}
          >
            <option value="" default selected>
              Select Salutation..
            </option>
            {salutations.map((option) => (
              <option style={{ color: "black" }} value={option}>
                {option}
              </option>
            ))}
          </select>
          <br /> */}

          {/* <label htmlFor="inviteMessage">
            <b>Email Message Body:</b>
          </label> */}
          {/* <textarea
            placeholder="Message Body."
            className="form-control form-control-md"
            id="inviteMessage"
            name="inviteMessage"
            onChange={this.handleMessageChange}
            rows="10"
            required
          ></textarea>
          <br/> */}

          {/* <div style={{ marginTop: 20, marginBottom: 20 }}>
            <RichTextEditor
              ref={quillRef}
              name="message_body"
              label="Email Message Body"
              onChange={handleEmailBodyChange}
              ariaLabel="email message html body"
              placeholder=""
              required={true}
              height={300}
              insertText={handleClicker}
              // defaultValue={}
              // submitPressed={submitPressed}
            />
          </div> */}
        <div style={{width: '320px', marginBottom: '30px'}}>
          <label htmlFor="selectfield"><b>Add Guest/Event Field</b></label>
                  <Select
                    // defaultValue={permissiondefault}
                    onChange={onSelectEventField}
                    options={eventfieldoptions}
                    placeholder="Add Guest Field..."
                  />
          </div>
          <div
            className={`uniqueTextEditor${uniqueTextEditor}`}
            id={"richTextEditor"}
          >
             <label htmlFor="email message body"><b>Email Message Body</b></label>
            <ReactQuill
            ref={quillRef}
            ariaLabel="testing aria"
            theme={"snow"}
            onChange={handleEmailBodyChange}
            onBlur={handleOnBlur}
            defaultValue={defaultEmailMessage}
            key={defaultEmailMessage}
            modules={EmailTicketsWizard.modules}
            formats={EmailTicketsWizard.formats}
            bounds={".app"}
            // placeholder={props.placeholder}
            // submitPressed={props.submitPressed}
          />

            {validationError.length != 0 ? (
              <div class="ui red pointing label">{validationError}</div>
            ) : null}

            {minimalError && validationError.length === 0 ? (
              <div class="ui red pointing label">
                {"Provide a minimum of 10 characters"}
              </div>
            ) : null}

            {submitPressedChange ? (
              <div>
                {submitPressed == true ? (
                  <div class="ui red pointing label">{`Email Message Body is required`}</div>
                ) : null}
              </div>
            ) : null}
          </div>

      <div style={{marginLeft: 2, marginTop: 20}} class="form-check">
    <label class="form-check-label">
      <input type="checkbox"
      onClick={()=>setQrCode(!qrCode)}
      name="termscheckbox"
      className="form-check-input"
       style={{marginTop:4}}/>
      <b className="agree_terms" style={{marginLeft: 5}}>Remove Guest QR Codes from Template</b>
    </label>
  </div>

          {/* <div>
            <button onClick={handleClicker}>Insert into Editor</button>
          </div> */}

          {/* <label htmlFor="inviteSignature">
            <b>Email Signature/Sign-Off:</b>
          </label>
            <textarea
            placeholder="Signature/Sign-Off."
            className="form-control form-control-md"
            id="inviteSignature"
            name="inviteSignature"
            onChange={this.handleSignatureChange}
            rows="4"
            required
          ></textarea> */}
        </div>

        {/* Attempt Side Preview */}
        <div className="form-group col messageContainer">
          <label htmlFor="Subject Preview">
            <b style={{fontSize:15}}>Subject Preview: </b>
            {messageSubject}
          </label>
          <br />
          <br />
          <label htmlFor="Sender Preview">
            <b style={{fontSize:15}}>Sender Name Preview: </b>
            {bestSenderName}
          </label>
          <br />
          <br />
          <label htmlFor="Email Preview">
            <b style={{fontSize:15}}>Email Preview:</b>
          </label>
          <br/>
          
          <div style={{width: '80%'}}>
          <Icon onClick={()=> {
            if(previewCount > 0){
            setPreviewCount(previewCount - 1)
            }
          }
            } style={{marginBottom: '1%'}} name="arrow alternate circle left" size="big" />
          <div style={{float: 'right'}}>
          <Icon onClick={()=>{
            if(previewCount < specificDashboard[0].lists.length - 1){
            setPreviewCount(previewCount + 1)
            }
          }
          } name="arrow alternate circle right" size="big" />
          </div>
          </div>

          <div
            style={{padding: '40px',
              borderRight: '20px solid #C8C8C8',
              borderLeft: '20px solid #C8C8C8',
              borderTop: '12px solid #C8C8C8',
              borderBottom: '12px solid #C8C8C8',
              borderRadius: '5px'
            }}
            className="form-control form-control-md"
            id="invitePreview"
            name="invitePreview"
          >
            <br /> <br />
            <div dangerouslySetInnerHTML={{ __html: fieldPreviewTranslator(inviteMessage) }} />

            <div style={{ position: "absolute", bottom: '35px', left:'100px'}}>
              {qrCode? 
            <QRCode
              size={140}
              value={JSON.stringify(specificDashboard[0].lists[0]._id)}
            />
            : null}
            </div>

            {/* {inviteMessage} */}
            <br /> <br />
            {inviteMessage === "" ? null : (
              <div>
                {/* <b>Event Name:</b> {specificDashboard[0].name}
                <br />
                <b>Name:</b> {specificDashboard[0].lists[0].list}
                <br />
                <b>Organization:</b>{" "}
                {specificDashboard[0].lists[0].organization}
                <br />
                <b>Table/Row #:</b>{" "}
                {specificDashboard[0].lists[0].table_row_number}
                <br />
                <b>Seat #:</b> {specificDashboard[0].lists[0].seat_number}
                <br />
                <b>Ticket Type:</b> {specificDashboard[0].lists[0].tickettype}
                <br />
                <b>Additional Details:</b>{" "}
                {specificDashboard[0].lists[0].guest_details}
                <br />
                <b>Confirmation Number:</b> {specificDashboard[0].lists[0]._id}
                <br /> */}

            {/* <li>Name {specificDashboard[0].lists[0].list}</li>
            <li>Organization {specificDashboard[0].lists[0].organization}</li>
            <li>Phone {specificDashboard[0].lists[0].phone}</li>
            <li>Email {specificDashboard[0].lists[0].email}</li>
            <li>Status {specificDashboard[0].lists[0].status}</li>
            <li>Table Row Number {specificDashboard[0].lists[0].table_row_number}</li>
            <li>Seat Number {specificDashboard[0].lists[0].seat_number}</li>
            <li>Ticket Type {specificDashboard[0].lists[0].tickettype}</li>
            <li>Guest Details {specificDashboard[0].lists[0].guest_details}</li>
            <li>Checked In By {specificDashboard[0].lists[0].checkedin_by}</li>
            <li>Checked In By Username {specificDashboard[0].lists[0].checkedin_by_username}</li>
            <li>Confirmation Number {specificDashboard[0].lists[0]._id}</li>
            <li>Event Name {specificDashboard[0].name}</li>
            <li>Event Date {moment(specificDashboard[0].startdate).format("MM-DD-YYYY")}</li>
            <li>Event End Date {moment(specificDashboard[0].enddate).format("MM-DD-YYYY")}</li>
            <li>Event Start Time {moment(specificDashboard[0].starttime, 'HH:mm').format('h:mm a')}</li>
            <li>Event End Time {moment(specificDashboard[0].endtime, 'HH:mm').format('h:mm a')}</li>
            <li>Event Timezone {specificDashboard[0].timezone}</li>
            <li>Event Location {specificDashboard[0].location}</li>
            <li>Event ID {specificDashboard[0]._id}</li> */}

              </div>
            )}
            
            {/* <br />
            {inviteSignature} */}

          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '30px', marginBottom: '50px' }}>
        <div>
          <Button
            className="addguestbutton"
            variant="info"
            onClick={
              () => setRedirectInvite(true)
            }
            style={{
              height: 40,
              borderRadius: 5,
              fontWeight: "bold",
              width: 300,
              fontSize: 14,
            }}
          >
            Go Back
          </Button>
        </div>

        <div>
          <Button
            className="addguestbutton"
            variant="info"
            onClick={() => {
              testEmailModalOpen()
              // Clearing Error Labels in Test Email Modal
              setTestEmailSubject(false);
              setTestEmailSender(false);
              setTestEmailRecipient(false);
            }}
            style={{
              height: 40,
              borderRadius: 5,
              fontWeight: "bold",
              width: 300,
              fontSize: 14,
            }}
          >
            Send a Test Email
          </Button>
        </div>

        <div>
          <Button
            className="addguestbutton"
            style={{
              height: 40,
              borderRadius: 5,
              fontWeight: "bold",
              width: 300,
              fontSize: 14,
            }}
            variant="info"
            onClick={() => {
              saveTemplateModalOpen();
              // setLoadstep3(false);
              // setLoadstep4(true);
            }}
          >
            <span style={{ fontFamily: "Verdana" }}>
              Save and Continue to Next Step
            </span>
          </Button>
        </div>

        <form 
        onSubmit={(e)=>{
          e.preventDefault();
          handleUpload()
        }}
        >
                    <div className="form-group">
                      <label htmlFor="description">Description:</label>
                      <input
                        type="text"
                        class="form-control"
                        name="description"
                        // onChange={onChange}
                        placeholder="Description"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="file"
                        name=""
                        id=""
                        // onChange={handleSelectedFile}
                      />
                    </div>
                    <button type="submit" class="btn btn-primary">
                      Upload
                    </button>
                  </form>
      </div>

      {/* <br />
      <Button
        // disabled={disableSendInvitation}
        onClick={() => sendInvites()}
        className="gobackButton"
        style={{
          marginTop: "3%",
          marginLeft: "42%",
          padding: 20,
          border: "1px solid darkgray",
          width: "25%",
          borderRadius: 25,
        }}
      >
        <b>Send Invites</b>
      </Button>
      <br /> */}
    </CardElementcontainer>
  );
  return <Fragment>{authRedirect}{dashboard}</Fragment>;
};

export default EmailTicketsWizard;

const addButton = {
  background: "pink",
  color: "black",
  fontWeight: "bold",
  fontSize: "14px",
  border: "1px solid black",
  padding: "5px 9px",
  borderRadius: "20%",
  width: "100%",
  cursor: "pointer",
};

EmailTicketsWizard.modules = {
  // #3 Add "image" to the toolbar
  toolbar: [
    [
      "bold",
      "italic",
      "underline",
      // 'strike',
      // 'blockquote',
    ],
    [
      // {'header': '1'}, {'header': '2'},
      // { 'font': []},
      { header: [] },
    ],
    // [{size: []}],
    // {'indent': '-1'}, {'indent': '+1'}],
    [{ list: "ordered" }, { list: "bullet" }],
    ['image'],
    // ['image' 'video'],
    ["link", { color: [] }, { background: [] }],
    ["clean"],
  ],
  // # 4 Add module and upload function
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);

        // const s3 = new AWS.S3({
        //   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        //   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        //   region: process.env.REACT_APP_AWS_REGION,
        //   signatureVersion: 'v4',
        // });

        // const params = {
        //   Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        //   Key: `${Date.now()}_${file.name}`,
        //   Body: file,
        //   ContentType: file.type,
        //   ACL: 'public-read',
        // };

        // s3.upload(params, (err, data) => {
        //   if (err) {
        //     console.log('Error uploading file:', err);
        //     reject('Upload failed');
        //   } else {
        //     console.log('File uploaded successfully:', data);
        //     resolve(data.Location);
        //   }
        // });

        console.log('file')
        console.log(file)

        console.log('file.name')
        console.log(file.name)

        console.log('file.target')
        console.log(file.target)
        
        console.log('file.size')
        console.log(file.size)
        
        console.log('file.lastModified')
        console.log(file.lastModified)

        console.log('file.lastModifiedDate')
        console.log(file.lastModifiedDate)
        
        console.log('file.type')
        console.log(file.type)

        console.log('formData')
        console.log(formData)

        console.log('UPLOADING!!!')
        
        axios.post('/api/emailtemplates/upload_image', formData)
        .then(res => {
        console.log("res")

        console.log(res)
        // resolve(res.data)
            resolve(
              // "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/480px-JavaScript-logo.png"
              res.data.data.Location
            );
        })
        .catch(err => {
        console.log(err)
        reject("Upload failed")
        })
      });
    },
  },
  clipboard: {
    // toggle to add extra iine breaks when pasting HTML:
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  },
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function (range, context) {
          return true;
        },
      },
    },
  },
};

EmailTicketsWizard.formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "indent",
  "color",
  "background",
  "link",
  "image",
  'width',
  'height',
  'style'
  // "imageBlot",
];