import React, { useState, useEffect, use } from "react";
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import "./AddGuestModal.css";
import { CostExplorer } from "aws-sdk";
import { set } from "lodash";
import { ToastContainer, toast } from "react-toastify";

export const AddGuestModal = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    tickettype: '',
    guest_details: '',
    organization: '',
    table_row_number: '',
    seat_number: '',
    guestPicture: '',
    guestPictureId: '',
    guestPictureName: '',
    guestPictureType: ''
  });

  const [currentGuestPicture, setCurrentGuestPicture] = useState({})
  const [myImage, setMyImage] = useState("")
  const [fileName, setFileName] = useState("")
  const [fileType, setFileType] = useState("")
  const [randomImageId, setRandomImageId] = useState("")

  const [guestPictureName, setGuestPictureName] = useState("")
  const [guestPictureType, setGuestPictureType] = useState("")
  const [pictureAdded, setPictureAdded] = useState(false)
  const [saveName, setSaveName] = useState(false)


  function isEmpty(obj) {
    for (const prop in currentGuestPicture) {
      if (Object.hasOwn(currentGuestPicture, prop)) {
        return false;
    }
  }
  return true;
}

  const onSubmit = (e) => {

    if (formData.name === '') {
      setSaveName(true)
      return
    }

    e.preventDefault();
    setSaveName(true)
    console.log("formData add Guest")
    console.log(formData)

    console.log("here is current Guest Picture")
    console.log(currentGuestPicture)

    if (pictureAdded === true) {

      console.log("currentGuestPicture is not empty - picture")
      console.log(currentGuestPicture)

      axios.post('/api/emailtemplates/upload_guest_image', currentGuestPicture)
        .then(res => {
          console.log("res")
          console.log(res)
          console.log("res.data Location")
          console.log(res.data.data.Location)
          setFormData({ ...formData, guestPicture: res.data.data.Location, guestPictureId: randomImageId, guestPictureName: fileName, guestPictureType: fileType });

          console.log("formData after setting guestPicture")
          console.log(formData)


          setTimeout(() => {

            console.log("formData after guestPicture")
            console.log(formData)

            let guestPicture = res.data.data.Location
            console.log("guestPicture")
            console.log(guestPicture)

            props.addGuest(props.id, formData.name, formData.email, formData.phone, formData.tickettype, formData.guest_details, formData.organization, formData.table_row_number, formData.seat_number, guestPicture, randomImageId, guestPictureName, guestPictureType);
            setFormData({ name: '', email: '', phone: '', tickettype: '', guest_details: '', organization: '', table_row_number: '', seat_number: '', guestPicture: '', guestPictureId: '', guestPictureName: '', guestPictureType: '' })
          }, 1000);
        })
    }
    else if (pictureAdded === false) {
      console.log("isEmpty currentGuestPicture")
      console.log(currentGuestPicture)

      props.addGuest(props.id, formData.name, formData.email, formData.phone, formData.tickettype, formData.guest_details, formData.organization, formData.table_row_number, formData.seat_number, formData.guestPicture, formData.guestPictureId, formData.guestPictureName, formData.guestPictureType);
      setFormData({ name: '', email: '', phone: '', tickettype: '', guest_details: '', organization: '', table_row_number: '', seat_number: '', guestPicture: '', guestPictureId: '', guestPictureName: '', guestPictureType: '' })

    }
    console.log("formData before timeout")
    console.log(formData)

    setCurrentGuestPicture({})
    setMyImage("")
    setPictureAdded(false)
    setSaveName(false)
    props.onHide()
  }

  //Single Event Handler Approach:
  const onChange = (e) => {

    setFormData({ ...formData, [e.target.name]: e.target.value });

    console.log("onchange formData")
    console.log(formData)

  }

  const onGuestImage = (e) => {

    if (e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpg" ||
      e.target.files[0].type == "image/heif" ||
      e.target.files[0].type == "image/heic" ||
      e.target.files[0].type == "image/JPEG" ||
      e.target.files[0].type == "image/PNG" ||
      e.target.files[0].type == "image/JPG" ||
      e.target.files[0].type == "image/HEIF" ||
      e.target.files[0].type == "image/HEIC"
    ) {

    const img = e.target.files[0]
    setMyImage(URL.createObjectURL(img))
    setPictureAdded(true);

    let randomImageId = Math.random().toString(36).slice(2)

    const formDataPic = new FormData();
    formDataPic.append("image", e.target.files[0]);
    formDataPic.append("guestPictureId", randomImageId);
    formDataPic.append("guestPictureName", e.target?.files[0]?.name);
    formDataPic.append("guestPictureType", e.target?.files[0]?.type);
    formDataPic.append("dashboardId", props.id);

    var fileName = e.target.files[0].name
    var fileType = e.target.files[0].type

    setFileName(fileName)
    setFileType(fileType)
    setRandomImageId(randomImageId)

    setGuestPictureName(fileName)
    setGuestPictureType(fileType)

    setCurrentGuestPicture(formDataPic)

    console.log("formDataPic")
    console.log(formDataPic)
    }
    else {
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>Please upload a valid image file type (jpeg, jpg, png)</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      onHide={() => {
        setFormData({ name: '', email: '', phone: '', tickettype: '', guest_details: '', organization: '', table_row_number: '', seat_number: '', guestPicture: '' })
        setMyImage("")
        setSaveName(false)
        setPictureAdded(false)
        // axios.post('/api/emailtemplates/delete_guest_image', currentGuestPicture)
        // .then(res => {
        // console.log("res")
        // console.log(res)
        // })
        props.onHide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div style={{ display: 'flex' }}>
            <div style={{ width: 150 }}>
              <b>Add Guest</b>
            </div>
            <div>
              <div style={{ height: 0, overFlow: "hidden" }}>
                <input type="file" id="guestPicture" name="guestPicture"
                  onChange={(e) => onGuestImage(e)} />
              </div>
              {pictureAdded !== true ? (
              <Button variant='info' type="button" onClick={() => document.getElementById('guestPicture').click()}>Upload Guest Picture</Button>
              ) : ( null)}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=''>
          <ToastContainer
            position="top-left"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <form onSubmit={onSubmit}>

            <div style={{ fontFamily: "Verdana" }} >
              {myImage !== "" ? (
                <div className="form-group guestPictureLogo"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    className="profileimage"
                    style={{
                      overflow: "hidden",
                      border: "1px solid gray",
                      borderRadius: "50%",
                      width: "80px",
                      height: "80px",
                    }}
                    src={myImage}
                    // src={"https://www.earth.com/assets/_next/image/?url=https%3A%2F%2Fcff2.earth.com%2Fuploads%2F2023%2F06%2F02100547%2FMountain-2-1400x850.jpg&w=1200&q=75"}
                    alt="profie pic"
                  ></img>

                  <div>
                    <i
                      className="fas fa-remove fa-xl deletePicture"
                      style={{ color: "crimson", position: "absolute", marginTop: "2px", marginLeft: "1px", cursor: 'pointer', fontSize: "20px" }}
                      onClick={() => {
                        setMyImage("")
                        setPictureAdded(false)
                      }}
                      ></i>
                  </div>

                </div>
              ) : (
                null)}
              <div className="form-group">
                <label for="name"><b>Name:</b></label>
                <input
                  id="name"
                  className="form-control form-control-md"
                  type="text"
                  name="name"
                  style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                  placeholder="Name..."
                  onChange={onChange}
                />
                {saveName && formData.name === '' && (
                <div class="ui pointing red basic label">
                    Template Name is Required
                </div>
                )}
              </div>

              <div className="form-group">
                <label for="organization"><b>Organization</b></label>
                <input
                  id="organization"
                  className="form-control form-control-md"
                  type="text"
                  name="organization"
                  style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                  placeholder="Organization..."
                  onChange={onChange}
                />
              </div>

              <div className="form-group">
                <label for="email"><b>Email:</b></label>

                <input
                  id="email"
                  className="form-control form-control-md"
                  type="text"
                  name="email"
                  style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                  placeholder="Email..."
                  onChange={onChange}
                />
              </div>


              <div className="form-group">
                <label for="phone"><b>Phone:</b></label>
                <input
                  id="phone"
                  className="form-control form-control-md"
                  type="text"
                  name="phone"
                  style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                  placeholder="Phone..."
                  onChange={onChange}
                />
              </div>




              <div className="form-group">
                <label for="ticket"><b>Ticket</b></label>
                <input
                  id="tickettype"
                  className="form-control form-control-md"
                  type="text"
                  name="tickettype"
                  style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                  placeholder="Ticket Type..."
                  onChange={onChange}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "48%" }} className="form-group">
                  <label for="ticket"><b>Table/Row Number</b></label>
                  <input id="table_row_number"
                    className="form-control form-control-md"
                    type="text"
                    name="table_row_number"
                    style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                    placeholder="Table/Row #..."
                    onChange={onChange}
                  />
                </div>

                <div style={{ width: "48%" }} className="form-group">
                  <label for="ticket"><b>Seat #</b></label>
                  <input id="seat_number"
                    className="form-control form-control-md"
                    type="text"
                    name="seat_number"
                    style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                    placeholder="Seat #..."
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="form-group">
                <label for="guest_details"><b>Additional Details</b></label>
                <textarea
                  id="guest_details"
                  className="form-control form-control-md"
                  type="text"
                  name="guest_details"
                  style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                  placeholder="Additional Details..."
                  onChange={onChange}
                />
              </div>

            </div>
          </form>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onSubmit}>Submit</Button>
        <Button variant="danger" onClick={() => {
          // axios.post('/api/emailtemplates/delete_guest_image', currentGuestPicture)
          // .then(res => {
          //   console.log("res")
          //   console.log(res)
          //   console.log("res.data Location")
          //   console.log(res.data.data.Location)
          // })
          // setFormData({ name: '', email: '', phone: '', tickettype: '', guest_details: '', organization: '', table_row_number: '', seat_number: '', guestPicture: '' })
          setMyImage("")
          setSaveName(false)
          setPictureAdded(false)
          props.onHide();
        }}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddGuestModal